//
// Themes variables
// --------------------------------------------------

// Amethyst Theme

$theme-amethyst-primary: #065E19 !default;
$theme-amethyst-light: lighten($theme-amethyst-primary, 15%) !default;
$theme-amethyst-lighter: lighten($theme-amethyst-primary, 28%) !default;
$theme-amethyst-dark: #3e4254 !default;
$theme-amethyst-darker: #2c2e3b !default;

$theme-amethyst-body-bg: #f0f1f2 !default;
$theme-amethyst-body-bg-dark: darken($theme-amethyst-body-bg, 4.5%) !default;
$theme-amethyst-body-bg-light: lighten($theme-amethyst-body-bg, 3%) !default;

$theme-amethyst-body-color: $theme-amethyst-dark !default;
$theme-amethyst-body-color-dark: $theme-amethyst-darker !default;
$theme-amethyst-body-color-light: $theme-amethyst-body-bg-dark !default;

$theme-amethyst-link-color: $theme-amethyst-primary !default;
$theme-amethyst-link-hover-color: darken(
        $theme-amethyst-link-color,
        20%
) !default;

$theme-amethyst-input-btn-focus-color: rgba(
        $theme-amethyst-primary,
        0.25
) !default;
$theme-amethyst-input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $theme-amethyst-input-btn-focus-color !default;

$theme-amethyst-input-bg: $white !default;
$theme-amethyst-input-color: $theme-amethyst-body-color !default;
$theme-amethyst-input-border-color: darken(
        $theme-amethyst-body-bg,
        8%
) !default;

$theme-amethyst-input-focus-bg: $white !default;
$theme-amethyst-input-focus-color: $theme-amethyst-body-color-dark !default;
$theme-amethyst-input-focus-border-color: lighten(
        $theme-amethyst-primary,
        25%
) !default;

$theme-amethyst-header-bg: rgba($white, 0.75) !default;
$theme-amethyst-header-dark-bg: rgba($theme-amethyst-darker, 1) !default;
$theme-amethyst-sidebar-bg: $white !default;
$theme-amethyst-sidebar-dark-bg: $theme-amethyst-darker !default;
$theme-amethyst-side-overlay-bg: $white !default;

// City Theme

$theme-city-primary: #e11d48 !default;
$theme-city-light: lighten($theme-city-primary, 18%) !default;
$theme-city-lighter: lighten($theme-city-primary, 32%) !default;
$theme-city-dark: #4b4b4b !default;
$theme-city-darker: #2b2b2b !default;

$theme-city-body-bg: #f2f2f2 !default;
$theme-city-body-bg-dark: darken($theme-city-body-bg, 4.5%) !default;
$theme-city-body-bg-light: lighten($theme-city-body-bg, 3%) !default;

$theme-city-body-color: $theme-city-dark !default;
$theme-city-body-color-dark: $theme-city-darker !default;
$theme-city-body-color-light: $theme-city-body-bg-dark !default;

$theme-city-link-color: $theme-city-primary !default;
$theme-city-link-hover-color: darken($theme-city-link-color, 20%) !default;

$theme-city-input-btn-focus-color: rgba($theme-city-primary, 0.25) !default;
$theme-city-input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $theme-city-input-btn-focus-color !default;

$theme-city-input-bg: $white !default;
$theme-city-input-color: $theme-city-body-color !default;
$theme-city-input-border-color: darken($theme-city-body-bg, 8%) !default;

$theme-city-input-focus-bg: $white !default;
$theme-city-input-focus-color: $theme-city-body-color-dark !default;
$theme-city-input-focus-border-color: lighten(
        $theme-city-primary,
        25%
) !default;

$theme-city-header-bg: rgba($white, 0.75) !default;
$theme-city-header-dark-bg: rgba($theme-city-darker, 1) !default;
$theme-city-sidebar-bg: $white !default;
$theme-city-sidebar-dark-bg: $theme-city-darker !default;
$theme-city-side-overlay-bg: $white !default;

// Flat Theme

$theme-flat-primary: #93BFD6 !default;
$theme-flat-light: lighten($theme-flat-primary, 15%) !default;
$theme-flat-lighter: lighten($theme-flat-primary, 28%) !default;
$theme-flat-dark: #36474d !default;
$theme-flat-darker: #2a363b !default;

$theme-flat-body-bg: #f0f2f2 !default;
$theme-flat-body-bg-dark: darken($theme-flat-body-bg, 4.5%) !default;
$theme-flat-body-bg-light: lighten($theme-flat-body-bg, 3%) !default;

$theme-flat-body-color: $theme-flat-dark !default;
$theme-flat-body-color-dark: $theme-flat-darker !default;
$theme-flat-body-color-light: $theme-flat-body-bg-dark !default;

$theme-flat-link-color: $theme-flat-primary !default;
$theme-flat-link-hover-color: darken(
        $theme-flat-link-color,
        20%
) !default;

$theme-flat-input-btn-focus-color: rgba(
        $theme-flat-primary,
        0.25
) !default;
$theme-flat-input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $theme-flat-input-btn-focus-color !default;

$theme-flat-input-bg: $white !default;
$theme-flat-input-color: $theme-flat-body-color !default;
$theme-flat-input-border-color: darken(
        $theme-flat-body-bg,
        8%
) !default;

$theme-flat-input-focus-bg: $white !default;
$theme-flat-input-focus-color: $theme-flat-body-color-dark !default;
$theme-flat-input-focus-border-color: lighten(
        $theme-flat-primary,
        25%
) !default;

$theme-flat-header-bg: rgba($white, 0.75) !default;
$theme-flat-header-dark-bg: rgba($theme-flat-darker, 1) !default;
$theme-flat-sidebar-bg: $white !default;
$theme-flat-sidebar-dark-bg: $theme-flat-darker !default;
$theme-flat-side-overlay-bg: $white !default;

// Modern Theme

$theme-modern-primary: #2563eb !default;
$theme-modern-light: lighten($theme-modern-primary, 20%) !default;
$theme-modern-lighter: lighten($theme-modern-primary, 40%) !default;
$theme-modern-dark: #323a47 !default;
$theme-modern-darker: #262d3b !default;

$theme-modern-body-bg: #f0f1f2 !default;
$theme-modern-body-bg-dark: darken($theme-modern-body-bg, 4.5%) !default;
$theme-modern-body-bg-light: lighten($theme-modern-body-bg, 3%) !default;

$theme-modern-body-color: $theme-modern-dark !default;
$theme-modern-body-color-dark: $theme-modern-darker !default;
$theme-modern-body-color-light: $theme-modern-body-bg-dark !default;

$theme-modern-link-color: $theme-modern-primary !default;
$theme-modern-link-hover-color: darken($theme-modern-link-color, 20%) !default;

$theme-modern-input-btn-focus-color: rgba($theme-modern-primary, 0.25) !default;
$theme-modern-input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $theme-modern-input-btn-focus-color !default;

$theme-modern-input-bg: $white !default;
$theme-modern-input-color: $theme-modern-body-color !default;
$theme-modern-input-border-color: darken($theme-modern-body-bg, 8%) !default;

$theme-modern-input-focus-bg: $white !default;
$theme-modern-input-focus-color: $theme-modern-body-color-dark !default;
$theme-modern-input-focus-border-color: lighten(
        $theme-modern-primary,
        25%
) !default;

$theme-modern-header-bg: rgba($white, 0.75) !default;
$theme-modern-header-dark-bg: rgba($theme-modern-darker, 1) !default;
$theme-modern-sidebar-bg: $white !default;
$theme-modern-sidebar-dark-bg: $theme-modern-darker !default;
$theme-modern-side-overlay-bg: $white !default;

// Smooth Theme

$theme-smooth-primary: #ea580c !default;
$theme-smooth-light: lighten($theme-smooth-primary, 15%) !default;
$theme-smooth-lighter: lighten($theme-smooth-primary, 28%) !default;
$theme-smooth-dark: #3c4554 !default;
$theme-smooth-darker: #2a303b !default;

$theme-smooth-body-bg: #f0f1f2 !default;
$theme-smooth-body-bg-dark: darken($theme-smooth-body-bg, 4.5%) !default;
$theme-smooth-body-bg-light: lighten($theme-smooth-body-bg, 3%) !default;

$theme-smooth-body-color: $theme-smooth-dark !default;
$theme-smooth-body-color-dark: $theme-smooth-darker !default;
$theme-smooth-body-color-light: $theme-smooth-body-bg-dark !default;

$theme-smooth-link-color: $theme-smooth-primary !default;
$theme-smooth-link-hover-color: darken(
        $theme-smooth-link-color,
        20%
) !default;

$theme-smooth-input-btn-focus-color: rgba(
        $theme-smooth-primary,
        0.25
) !default;
$theme-smooth-input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $theme-smooth-input-btn-focus-color !default;

$theme-smooth-input-bg: $white !default;
$theme-smooth-input-color: $theme-smooth-body-color !default;
$theme-smooth-input-border-color: darken(
        $theme-smooth-body-bg,
        8%
) !default;

$theme-smooth-input-focus-bg: $white !default;
$theme-smooth-input-focus-color: $theme-smooth-body-color-dark !default;
$theme-smooth-input-focus-border-color: lighten(
        $theme-smooth-primary,
        25%
) !default;

$theme-smooth-header-bg: rgba($white, 0.75) !default;
$theme-smooth-header-dark-bg: rgba($theme-smooth-darker, 1) !default;
$theme-smooth-sidebar-bg: $white !default;
$theme-smooth-sidebar-dark-bg: $theme-smooth-darker !default;
$theme-smooth-side-overlay-bg: $white !default;


// Cyber Theme

$theme-cyber-primary: #F2E307 !default;
$theme-cyber-light: lighten($theme-cyber-primary, 15%) !default;
$theme-cyber-lighter: lighten($theme-cyber-primary, 28%) !default;
$theme-cyber-dark: #980BBF !default;
$theme-cyber-darker: #024059 !default;

$theme-cyber-body-bg: #ffffff !default;
$theme-cyber-body-bg-dark: darken($theme-cyber-body-bg, 4.5%) !default;
$theme-cyber-body-bg-light: lighten($theme-cyber-body-bg, 3%) !default;

$theme-cyber-body-color: $theme-cyber-dark !default;
$theme-cyber-body-color-dark: $theme-cyber-darker !default;
$theme-cyber-body-color-light: $theme-cyber-body-bg-dark !default;

$theme-cyber-link-color: $theme-cyber-primary !default;
$theme-cyber-link-hover-color: darken(
        $theme-cyber-link-color,
        20%
) !default;

$theme-cyber-input-btn-focus-color: rgba(
        $theme-cyber-primary,
        0.25
) !default;
$theme-cyber-input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $theme-cyber-input-btn-focus-color !default;

$theme-cyber-input-bg: $white !default;
$theme-cyber-input-color: $theme-cyber-body-color !default;
$theme-cyber-input-border-color: darken(
        $theme-cyber-body-bg,
        8%
) !default;

$theme-cyber-input-focus-bg: $white !default;
$theme-cyber-input-focus-color: $theme-cyber-body-color-dark !default;
$theme-cyber-input-focus-border-color: lighten(
        $theme-cyber-primary,
        25%
) !default;

$theme-cyber-header-bg: rgba($white, 0.75) !default;
$theme-cyber-header-dark-bg: rgba($theme-cyber-darker, 1) !default;
$theme-cyber-sidebar-bg: $white !default;
$theme-cyber-sidebar-dark-bg: $theme-cyber-darker !default;
$theme-cyber-side-overlay-bg: $white !default;
