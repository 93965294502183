//
// OneUI variables
// --------------------------------------------------

// Colors

$primary-light: tint-color($primary, 35%) !default;
$primary-lighter: tint-color($primary, 65%) !default;
$primary-dark: #4b5563 !default;
$primary-darker: #1f2937 !default;
$success-light: tint-color($success, 80%) !default;
$info-light: tint-color($info, 80%) !default;
$warning-light: tint-color($warning, 80%) !default;
$danger-light: tint-color($danger, 80%) !default;

$body-bg-dark: darken($body-bg, 4.5%) !default;
$body-bg-light: lighten($body-bg, 3.5%) !default;

$body-color-dark: shade-color($body-color, 8%) !default;
$body-color-light: #e9ecef !default;

// Paragraphs

$paragraph-line-height: 1.6 !default;
$paragraph-line-height-lg: 1.8 !default;

// Z-index master list additions

$zindex-block-pinned: $zindex-fixed + 1 !default;
$zindex-sidebar: $zindex-fixed + 2 !default;
$zindex-page-overlay: $zindex-fixed + 3 !default;
$zindex-side-overlay: $zindex-fixed + 4 !default;
$zindex-block-fullscreen: $zindex-fixed + 5 !default;

// Header

$header-height: 4rem !default; // Best values > 3rem and < 5rem
$header-bg: rgba($white, 0.75) !default;
$header-dark-bg: rgba($primary-darker, 1) !default;

// Sidebar and Side Overlay

$sidebar-bg: $white !default;
$sidebar-dark-bg: $primary-darker !default;
$sidebar-width: 250px !default;

$sidebar-mini-width: 60px !default;

$sidebar-mini-nav-width: 46px !default;

$side-overlay-bg: $white !default;
$side-overlay-width: 320px !default;

$side-transition: 0.28s ease-out !default;

// Main Navigation

$main-nav-transition: 0.25s ease-out !default;

// Layout Spaces

$space-base: 1.875rem !default; // Content padding
$space-mobile: 1rem !default; // Content padding in mobile
$space-side: 1.25rem !default; // Content padding inside side content
$space-row-gutter-tiny: 0.375rem !default; // Row with tiny gutter
$space-block: 1.25rem !default; // Block padding
$space-boxed: 1280px !default; // Boxed content max width
$space-narrow: 100% !default; // Narrow content max width
$space-main-max-width: 1920px !default; // Main content max width

// Third Party

$fa-font-path: "../fonts/fontawesome" !default;
