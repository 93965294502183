//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #a48ad4;
.fe-body {
    // global
    font-weight: $font-weight-light;
    font-size: $font-size-base;

    p {
        font-weight: $font-weight-light;
        font-size: $font-size-base;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $font-weight-medium;
    }

    .container-fit {
        width: 50%;
        margin: auto;
        @include media-breakpoint-only(xs) {
            width: 100%;
            padding-left: 12%;
            padding-right: 12%;
        }
    }

    .container-wide {
        width: 100%;
        padding-left: 12%;
        padding-right: 12%;
    }

    // header
    #page-header {
        border-bottom: 1px solid $body-bg-dark;

        &.fixed {
            position: fixed;
            z-index: 10;
            top: 0;
        }

        .logo {
            img {
                max-height: 40px;
            }
        }

        .nav-main {
            .nav-main-link {
                font-weight: $font-weight-light;
                font-size: $font-size-base;
                border-bottom: 2px solid transparent;
                position: relative;

                .si {
                    position: absolute;
                    top: 18px;
                    font-size: 10px;
                    right: 0;
                }
            }

            .nav-main-link,
            .nav-main-item {
                &:hover,
                &:focus {
                    font-weight: $font-weight-light;
                    background-color: transparent !important;
                }
            }
        }

        button {
            position: relative;
            top: 8px;

            &:hover,
            &:focus {
                @include removeBlueArea();
            }
        }
    }

    // Sidebar
    #sidebar {
        .logo {
            img {
                max-height: 45px;
            }
        }

        .nav-main {
            .nav-main-link {
                position: relative;

                .si {
                    position: absolute;
                    right: 29px;
                    top: 13px;
                }
            }

            .nav-main-child {
                background-color: rgba(0, 0, 0, 0.1);
                padding-left: 0px;

                .nav-main-item {
                    list-style: none !important;

                    .nav-main-link {
                        padding-left: 1.2rem;
                    }
                }
            }
        }
    }

    // Content
    .fe-content-container {
        .fe-content-item {

            &:nth-child(even) {
                background-color: $body-bg-light;
                // tab
                .content-type-tab {
                    .nav-link.active {
                        background-color: $body-bg-light;
                        border-bottom: 1px solid $body-bg-light;
                    }
                }
            }

            // global
            .content-padding {
                padding: 4rem 0;
            }

            .btn {
                @include transition();
                transform: translateY(0);

                &:hover {
                    @include transition();
                    transform: translateY(-3px);
                }
            }

            // text
            .content-type-text {
            }

            // text-image
            .content-type-text-image {
            }

            // text
            .content-type-image {
                .cover {
                    max-height: 100vh;
                    overflow: hidden;
                    object-fit: cover;

                    picture {
                        border-radius: 0px !important;
                        overflow: initial;

                        img {
                            object-fit: cover;
                        }

                        figcaption {
                            bottom: 1rem;
                        }
                    }
                }

                .contain {
                    img {
                        max-width: 100%;
                    }
                }
            }

            // youtube
            .content-type-youtube {
                .ratio {
                    overflow: hidden;
                    border-radius: 10px;
                }

                .cover {
                    iframe {
                        min-height: 100vh;
                        @include media-breakpoint-only(xs) {
                            min-height: 100%;
                        }
                    }
                }
            }

            // article

            .content-type-article {
                [class*="col-"] {
                    float: none;
                    display: table-cell;
                    vertical-align: top;

                    .block {
                        height: 100%;
                        overflow: hidden;
                        @include transition();
                        position: relative;

                        picture {
                            @include transition();
                            position: relative;
                            overflow: hidden;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;

                            img {
                                @include transition();
                                transform: scale(1);
                            }
                        }

                        .block-footer {
                            position: absolute;
                            bottom: 0;
                            width: 100%;

                            .btn {
                                border-top-left-radius: 0px;
                                border-top-right-radius: 0px;
                            }
                        }

                        &:hover {
                            @include boxShadow();
                            -webkit-transform: translateY(-10px);
                            -ms-transform: translateY(-10px);
                            transform: translateY(-10px);
                        }

                        .btn {
                            &:hover, &:focus {
                                transform: translateY(0) !important;
                            }
                        }

                        &:hover, &:focus {
                            picture {
                                img {
                                    transform: scale(1.25);
                                }
                            }
                        }
                    }
                }

                .page-item.disabled .page-link {
                    background-color: transparent;
                    border-color: transparent;
                    box-shadow: none;
                }
            }

            // carousel
            .content-type-carousel {
                height: 100vh;

                .carousel-inner {
                    height: 100vh;

                    .carousel-item {
                        position: relative;

                        img {
                            object-fit: cover;
                            object-position: 50% 50%;
                            width: 100%;
                            height: 100vh;
                        }
                    }

                    .carousel-caption {
                        max-width: 75%;
                        top: 30%;
                        bottom: 30%;
                        @include media-breakpoint-only(xs) {
                            max-width: 100%;
                            top: auto;
                            bottom: 10%;
                        }

                        &.text-start {
                            text-align: left;
                            left: 12%;
                        }

                        &.text-center {
                            text-align: center;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }

                        &.text-end {
                            text-align: right;
                            left: auto;
                            right: 12%;
                        }

                        h1 {
                            @include textShadow();
                            font-size: 6rem;
                            @include media-breakpoint-only(xs) {
                                font-size: 4rem !important;
                            }
                        }

                        p {
                            @include textShadow();
                            font-size: 1.5rem;
                            @include media-breakpoint-only(xs) {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }

                .carousel-control-prev,
                .carousel-control-next {
                    width: 100px;
                    height: 100px;
                    top: 46%;

                    .carousel-control-prev-icon,
                    .carousel-control-next-icon {
                        width: 4rem;
                        height: 4rem;
                    }

                    .carousel-control-prev-icon {
                        left: -10px !important;
                        position: relative;
                    }

                    .carousel-control-next-icon {
                        right: -10px !important;
                        position: relative;
                    }
                }

                .carousel-indicators {
                    [data-bs-target] {
                        height: 5px !important;

                        &.active {
                            background-color: $primary !important;
                        }
                    }
                }
            }

            // card
            .content-type-card {
                .card-item-container {
                    display: flex;
                    align-items: stretch;
                    justify-content: space-around; /*some space between cards*/
                    .card-item {
                        @include transition();
                        border-radius: 6px;

                        &:hover {
                            background-color: $white;
                            @include boxShadow();
                            -webkit-transform: translateY(-10px);
                            -ms-transform: translateY(-10px);
                            transform: translateY(-10px);
                        }

                        .card-item-image {
                            picture {
                                max-width: 100px;
                                border-radius: 1000px;
                                display: block;
                                overflow: hidden;
                            }
                        }

                        .card-item-content {
                            h3 {
                                margin: 1rem 0 !important;
                            }

                            p {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                height: 130px;
                                max-width: 250px;
                                white-space: break-spaces;
                            }
                        }

                        &.left {
                            .card-item-image {
                                position: relative;
                                top: 1.4rem;
                            }
                        }

                        &.top {
                            text-align: center;

                            .card-item-image {
                                picture {
                                    margin: auto;
                                }
                            }

                            .card-item-content {
                                margin: auto;

                                p {
                                    margin: auto;
                                }
                            }
                        }
                    }

                    .col-md-12 {
                        .card-item-content {
                            p {
                                max-width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            // accordion
            .content-type-accordion {
                .accordion {
                    .accordion-header {

                        .accordion-button {
                            box-shadow: none;
                            @include transition();
                            background-color: transparent;
                            border-bottom: 2px solid rgba(0, 0, 0, 0.01);
                            color: $dark;

                            &:hover,
                            &:focus {
                                @include removeBlueArea();
                                background-color: rgba(0, 0, 0, 0.1);
                                border-bottom: 2px solid $body-color-dark;
                                color: $dark;

                                &:after {
                                    transform: rotate(0deg);
                                }
                            }

                            &:after {
                                filter: grayscale(1);
                            }
                        }

                        &:hover,
                        &:focus {
                            .accordion-button {
                                &:after {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }

                    .accordion-item {
                        background-color: transparent !important;
                        border: 0 !important;
                        border-radius: 0 !important;
                    }
                }
            }

            // attachment
            .content-type-attachment {
                .attachment-item {
                    .attachment-item-file {
                        .btn-download {
                            .si {
                                position: absolute;
                                top: -1rem;
                                right: -1rem;
                                padding: 0.5rem;
                                border-radius: 1000px;
                            }

                            picture {
                                box-shadow: none !important;
                                position: relative;
                                top: 4px;
                                left: 5px;

                                img {
                                    margin: 0.6rem auto;
                                    width: 30px;
                                }
                            }

                            h4 {
                                margin: 0;
                                position: relative;
                                top: 4px;
                            }

                            &:hover {
                                .si {
                                    @include transition();
                                    top: -0.5rem;
                                }
                            }
                        }

                        .spinner-grow {
                            position: relative;
                            top: 14px;
                            left: 5px;
                        }
                    }

                    p {
                        max-width: 300px;
                    }
                }
            }

            // quote
            .content-type-quote {
                blockquote {
                    background-color: transparent;
                    font-size: 1.25rem;
                }

                .swiper {
                    background-color: $card-bg;
                    background-clip: border-box;
                    border: $card-border-width solid $card-border-color;
                    @include border-radius($card-border-radius);
                    @include box-shadow($card-box-shadow);

                    .swiper-pagination {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 1rem;

                        .swiper-pagination-bullet {
                            cursor: pointer;
                            display: block;
                            width: 30px;
                            height: 5px;
                            margin-right: 3px;
                            margin-left: 3px;
                            background-color: $secondary;
                            opacity: 0.5;

                            &:hover, &:focus, &.swiper-pagination-bullet-active {
                                background-color: $primary;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    // Article detail
    .article-detail {
        padding: 2rem 0;

        picture {
            margin: auto;
            position: relative;
            display: block;
            overflow: hidden;

            img {
                margin: auto;
            }
        }
    }

    // Article detail
    .article-detail {
        padding: 2rem 0;

        picture {
            margin: auto;
            position: relative;
            display: block;
            overflow: hidden;

            img {
                margin: auto;
            }
        }

        .article-item-container {
            [class*="col-"] {
                float: none;
                display: table-cell;
                vertical-align: top;

                .block {
                    height: 100%;
                    overflow: hidden;
                    @include transition();
                    position: relative;

                    picture {
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }

                    .block-footer {
                        position: absolute;
                        bottom: 0;
                        width: 100%;

                        .btn {
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                        }
                    }

                    &:hover {
                        @include boxShadow();
                        -webkit-transform: translateY(-10px);
                        -ms-transform: translateY(-10px);
                        transform: translateY(-10px);
                    }
                }
            }
        }
    }

    // footer
    #page-footer {
        border-top: 1px solid $body-bg-dark;
    }

    .dark-mode {
        #page-header {
            border-bottom: 1px solid $dark;
        }

        .fe-content-item {
            &:nth-child(even) {
                background-color: $black;
                // tab
                .content-type-tab {
                    .nav-link.active {
                        background-color: $black;
                        border-bottom: 1px solid $black;
                    }
                }
            }

            // card
            .content-type-card {
                .card-item {
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.2) !important;
                    }
                }
            }

            // accordion
            .content-type-accordion {
                .accordion-button {
                    background-color: transparent;
                    color: $white !important;
                    border-bottom: 2px solid rgba(0, 0, 0, 0.01);

                    &:hover,
                    &:focus {
                        color: $white !important;
                        border-bottom: 2px solid $white !important;
                    }
                }
            }

            // attachment
            .content-type-attachment {
                .btn-light {
                    background-color: $color-contrast-dark !important;
                }
            }
        }

        #page-footer {
            border-top: 1px solid $dark;
            color: $white;
        }
    }
}
