//
// Ribbon
// --------------------------------------------------

@mixin ribbon-variation($bg-color, $text-color) {
  .ribbon-box {
    color: $text-color;
    background-color: $bg-color;
  }

  &.ribbon-bookmark {
    .ribbon-box::before {
      border-color: $bg-color;
      border-left-color: transparent;
    }

    &.ribbon-left .ribbon-box::before {
      border-color: $bg-color;
      border-right-color: transparent;
    }
  }

  &.ribbon-modern {
    .ribbon-box::before {
      border-color: $bg-color;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }

    &.ribbon-bottom .ribbon-box::before {
      border-color: $bg-color;
      border-top-color: transparent;
      border-left-color: transparent;
    }

    &.ribbon-left .ribbon-box::before {
      border-color: $bg-color;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }

    &.ribbon-left.ribbon-bottom .ribbon-box::before {
      border-color: $bg-color;
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
}
