.fe-body {
  font-weight: 300;
  font-size: 1rem;
}
.fe-body p {
  font-weight: 300;
  font-size: 1rem;
}
.fe-body h1,
.fe-body h2,
.fe-body h3,
.fe-body h4,
.fe-body h5,
.fe-body h6 {
  font-weight: 500;
}
.fe-body .container-fit {
  width: 50%;
  margin: auto;
}
@media (max-width: 575.98px) {
  .fe-body .container-fit {
    width: 100%;
    padding-left: 12%;
    padding-right: 12%;
  }
}
.fe-body .container-wide {
  width: 100%;
  padding-left: 12%;
  padding-right: 12%;
}
.fe-body #page-header {
  border-bottom: 1px solid #dde2e9;
}
.fe-body #page-header.fixed {
  position: fixed;
  z-index: 10;
  top: 0;
}
.fe-body #page-header .logo img {
  max-height: 40px;
}
.fe-body #page-header .nav-main .nav-main-link {
  font-weight: 300;
  font-size: 1rem;
  border-bottom: 2px solid transparent;
  position: relative;
}
.fe-body #page-header .nav-main .nav-main-link .si {
  position: absolute;
  top: 18px;
  font-size: 10px;
  right: 0;
}
.fe-body #page-header .nav-main .nav-main-link:hover, .fe-body #page-header .nav-main .nav-main-link:focus,
.fe-body #page-header .nav-main .nav-main-item:hover,
.fe-body #page-header .nav-main .nav-main-item:focus {
  font-weight: 300;
  background-color: transparent !important;
}
.fe-body #page-header button {
  position: relative;
  top: 8px;
}
.fe-body #page-header button:hover, .fe-body #page-header button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.fe-body #sidebar .logo img {
  max-height: 45px;
}
.fe-body #sidebar .nav-main .nav-main-link {
  position: relative;
}
.fe-body #sidebar .nav-main .nav-main-link .si {
  position: absolute;
  right: 29px;
  top: 13px;
}
.fe-body #sidebar .nav-main .nav-main-child {
  background-color: rgba(0, 0, 0, 0.1);
  padding-left: 0px;
}
.fe-body #sidebar .nav-main .nav-main-child .nav-main-item {
  list-style: none !important;
}
.fe-body #sidebar .nav-main .nav-main-child .nav-main-item .nav-main-link {
  padding-left: 1.2rem;
}
.fe-body .fe-content-container .fe-content-item:nth-child(even) {
  background-color: #f6f7f9;
}
.fe-body .fe-content-container .fe-content-item:nth-child(even) .content-type-tab .nav-link.active {
  background-color: #f6f7f9;
  border-bottom: 1px solid #f6f7f9;
}
.fe-body .fe-content-container .fe-content-item .content-padding {
  padding: 4rem 0;
}
.fe-body .fe-content-container .fe-content-item .btn {
  transition: all 0.2s ease-in-out;
  transform: translateY(0);
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .btn {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .btn:hover {
  transition: all 0.2s ease-in-out;
  transform: translateY(-3px);
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .btn:hover {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-image .cover {
  max-height: 100vh;
  overflow: hidden;
  object-fit: cover;
}
.fe-body .fe-content-container .fe-content-item .content-type-image .cover picture {
  border-radius: 0px !important;
  overflow: initial;
}
.fe-body .fe-content-container .fe-content-item .content-type-image .cover picture img {
  object-fit: cover;
}
.fe-body .fe-content-container .fe-content-item .content-type-image .cover picture figcaption {
  bottom: 1rem;
}
.fe-body .fe-content-container .fe-content-item .content-type-image .contain img {
  max-width: 100%;
}
.fe-body .fe-content-container .fe-content-item .content-type-youtube .ratio {
  overflow: hidden;
  border-radius: 10px;
}
.fe-body .fe-content-container .fe-content-item .content-type-youtube .cover iframe {
  min-height: 100vh;
}
@media (max-width: 575.98px) {
  .fe-body .fe-content-container .fe-content-item .content-type-youtube .cover iframe {
    min-height: 100%;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] {
  float: none;
  display: table-cell;
  vertical-align: top;
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block {
  height: 100%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block picture {
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block picture {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block picture img {
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block picture img {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block .block-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block .block-footer .btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block:hover {
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block .btn:hover, .fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block .btn:focus {
  transform: translateY(0) !important;
}
.fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block:hover picture img, .fe-body .fe-content-container .fe-content-item .content-type-article [class*=col-] .block:focus picture img {
  transform: scale(1.25);
}
.fe-body .fe-content-container .fe-content-item .content-type-article .page-item.disabled .page-link {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel {
  height: 100vh;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner {
  height: 100vh;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-item {
  position: relative;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-item img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100vh;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption {
  max-width: 75%;
  top: 30%;
  bottom: 30%;
}
@media (max-width: 575.98px) {
  .fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption {
    max-width: 100%;
    top: auto;
    bottom: 10%;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption.text-start {
  text-align: left;
  left: 12%;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption.text-center {
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption.text-end {
  text-align: right;
  left: auto;
  right: 12%;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption h1 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  font-size: 6rem;
}
@media (max-width: 575.98px) {
  .fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption h1 {
    font-size: 4rem !important;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption p {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  font-size: 1.5rem;
}
@media (max-width: 575.98px) {
  .fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-inner .carousel-caption p {
    font-size: 1.2rem;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-prev,
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-next {
  width: 100px;
  height: 100px;
  top: 46%;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-prev .carousel-control-prev-icon,
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-prev .carousel-control-next-icon,
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-next .carousel-control-prev-icon,
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-next .carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-prev .carousel-control-prev-icon,
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-next .carousel-control-prev-icon {
  left: -10px !important;
  position: relative;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-prev .carousel-control-next-icon,
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-control-next .carousel-control-next-icon {
  right: -10px !important;
  position: relative;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-indicators [data-bs-target] {
  height: 5px !important;
}
.fe-body .fe-content-container .fe-content-item .content-type-carousel .carousel-indicators [data-bs-target].active {
  background-color: #4c78dd !important;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container {
  display: flex;
  align-items: stretch;
  justify-content: space-around; /*some space between cards*/
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item {
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item:hover {
  background-color: #fff;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item .card-item-image picture {
  max-width: 100px;
  border-radius: 1000px;
  display: block;
  overflow: hidden;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item .card-item-content h3 {
  margin: 1rem 0 !important;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item .card-item-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 130px;
  max-width: 250px;
  white-space: break-spaces;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item.left .card-item-image {
  position: relative;
  top: 1.4rem;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item.top {
  text-align: center;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item.top .card-item-image picture {
  margin: auto;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item.top .card-item-content {
  margin: auto;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .card-item.top .card-item-content p {
  margin: auto;
}
.fe-body .fe-content-container .fe-content-item .content-type-card .card-item-container .col-md-12 .card-item-content p {
  max-width: 100%;
  height: 100%;
}
.fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button {
  box-shadow: none;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  border-bottom: 2px solid rgba(0, 0, 0, 0.01);
  color: #334155;
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button:hover, .fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #2f3c4e;
  color: #334155;
}
.fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button:hover:after, .fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button:focus:after {
  transform: rotate(0deg);
}
.fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header .accordion-button:after {
  filter: grayscale(1);
}
.fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header:hover .accordion-button:after, .fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-header:focus .accordion-button:after {
  transform: rotate(0deg);
}
.fe-body .fe-content-container .fe-content-item .content-type-accordion .accordion .accordion-item {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .btn-download .si {
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 0.5rem;
  border-radius: 1000px;
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .btn-download picture {
  box-shadow: none !important;
  position: relative;
  top: 4px;
  left: 5px;
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .btn-download picture img {
  margin: 0.6rem auto;
  width: 30px;
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .btn-download h4 {
  margin: 0;
  position: relative;
  top: 4px;
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .btn-download:hover .si {
  transition: all 0.2s ease-in-out;
  top: -0.5rem;
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .btn-download:hover .si {
    transition: none;
  }
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item .attachment-item-file .spinner-grow {
  position: relative;
  top: 14px;
  left: 5px;
}
.fe-body .fe-content-container .fe-content-item .content-type-attachment .attachment-item p {
  max-width: 300px;
}
.fe-body .fe-content-container .fe-content-item .content-type-quote blockquote {
  background-color: transparent;
  font-size: 1.25rem;
}
.fe-body .fe-content-container .fe-content-item .content-type-quote .swiper {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #dde2e9;
  border-radius: 0.3rem;
}
.fe-body .fe-content-container .fe-content-item .content-type-quote .swiper .swiper-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.fe-body .fe-content-container .fe-content-item .content-type-quote .swiper .swiper-pagination .swiper-pagination-bullet {
  cursor: pointer;
  display: block;
  width: 30px;
  height: 5px;
  margin-right: 3px;
  margin-left: 3px;
  background-color: #475569;
  opacity: 0.5;
}
.fe-body .fe-content-container .fe-content-item .content-type-quote .swiper .swiper-pagination .swiper-pagination-bullet:hover, .fe-body .fe-content-container .fe-content-item .content-type-quote .swiper .swiper-pagination .swiper-pagination-bullet:focus, .fe-body .fe-content-container .fe-content-item .content-type-quote .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #4c78dd;
  opacity: 1;
}
.fe-body .article-detail {
  padding: 2rem 0;
}
.fe-body .article-detail picture {
  margin: auto;
  position: relative;
  display: block;
  overflow: hidden;
}
.fe-body .article-detail picture img {
  margin: auto;
}
.fe-body .article-detail {
  padding: 2rem 0;
}
.fe-body .article-detail picture {
  margin: auto;
  position: relative;
  display: block;
  overflow: hidden;
}
.fe-body .article-detail picture img {
  margin: auto;
}
.fe-body .article-detail .article-item-container [class*=col-] {
  float: none;
  display: table-cell;
  vertical-align: top;
}
.fe-body .article-detail .article-item-container [class*=col-] .block {
  height: 100%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
}
@media (prefers-reduced-motion: reduce) {
  .fe-body .article-detail .article-item-container [class*=col-] .block {
    transition: none;
  }
}
.fe-body .article-detail .article-item-container [class*=col-] .block picture {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.fe-body .article-detail .article-item-container [class*=col-] .block .block-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fe-body .article-detail .article-item-container [class*=col-] .block .block-footer .btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.fe-body .article-detail .article-item-container [class*=col-] .block:hover {
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.fe-body #page-footer {
  border-top: 1px solid #dde2e9;
}
.fe-body .dark-mode #page-header {
  border-bottom: 1px solid #334155;
}
.fe-body .dark-mode .fe-content-item:nth-child(even) {
  background-color: #000;
}
.fe-body .dark-mode .fe-content-item:nth-child(even) .content-type-tab .nav-link.active {
  background-color: #000;
  border-bottom: 1px solid #000;
}
.fe-body .dark-mode .fe-content-item .content-type-card .card-item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.fe-body .dark-mode .fe-content-item .content-type-accordion .accordion-button {
  background-color: transparent;
  color: #fff !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.01);
}
.fe-body .dark-mode .fe-content-item .content-type-accordion .accordion-button:hover, .fe-body .dark-mode .fe-content-item .content-type-accordion .accordion-button:focus {
  color: #fff !important;
  border-bottom: 2px solid #fff !important;
}
.fe-body .dark-mode .fe-content-item .content-type-attachment .btn-light {
  background-color: #000 !important;
}
.fe-body .dark-mode #page-footer {
  border-top: 1px solid #334155;
  color: #fff;
}

body {
  color: #3e4254;
  background-color: #f0f1f2;
}

a {
  color: #065E19;
}
a.link-fx::before {
  background-color: #065E19;
}
a:hover {
  color: black;
}

hr {
  border-top-color: #e4e6e7;
}

.text-primary {
  color: #065E19 !important;
}

a.text-primary.link-fx::before {
  background-color: #065E19 !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #032e0c !important;
}

.text-primary-dark {
  color: #3e4254 !important;
}

a.text-primary-dark.link-fx::before {
  background-color: #3e4254 !important;
}
a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #282b37 !important;
}

.text-primary-darker {
  color: #2c2e3b !important;
}

a.text-primary-darker.link-fx::before {
  background-color: #2c2e3b !important;
}
a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #16171e !important;
}

.text-primary-light {
  color: #0ba62c !important;
}

a.text-primary-light.link-fx::before {
  background-color: #0ba62c !important;
}
a.text-primary-light:hover, a.text-primary-light:focus {
  color: #08761f !important;
}

.text-primary-lighter {
  color: #0fe43d !important;
}

a.text-primary-lighter.link-fx::before {
  background-color: #0fe43d !important;
}
a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #0cb430 !important;
}

.text-body-bg {
  color: #f0f1f2 !important;
}

a.text-body-bg.link-fx::before {
  background-color: #f0f1f2 !important;
}
a.text-body-bg:hover, a.text-body-bg:focus {
  color: #d5d8da !important;
}

.text-body-bg-light {
  color: #f8f9f9 !important;
}

a.text-body-bg-light.link-fx::before {
  background-color: #f8f9f9 !important;
}
a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #dddfe1 !important;
}

.text-body-bg-dark {
  color: #e4e6e7 !important;
}

a.text-body-bg-dark.link-fx::before {
  background-color: #e4e6e7 !important;
}
a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #c8ccd0 !important;
}

.text-body-color {
  color: #3e4254 !important;
}

a.text-body-color.link-fx::before {
  background-color: #3e4254 !important;
}
a.text-body-color:hover, a.text-body-color:focus {
  color: #282b37 !important;
}

.text-body-color-dark {
  color: #2c2e3b !important;
}

a.text-body-color-dark.link-fx::before {
  background-color: #2c2e3b !important;
}
a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: #16171e !important;
}

.text-body-color-light {
  color: #e4e6e7 !important;
}

a.text-body-color-light.link-fx::before {
  background-color: #e4e6e7 !important;
}
a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #c8ccd0 !important;
}

.text-dual {
  color: #3e4254 !important;
}

a.text-dual.link-fx::before {
  background-color: #3e4254 !important;
}
a.text-dual:hover, a.text-dual:focus {
  color: #282b37 !important;
}

.page-header-dark #page-header .text-dual,
.sidebar-dark #sidebar .text-dual {
  color: #e4e6e7 !important;
}
.page-header-dark #page-header a.text-dual.link-fx::before,
.sidebar-dark #sidebar a.text-dual.link-fx::before {
  background-color: #e4e6e7 !important;
}
.page-header-dark #page-header a.text-dual:hover, .page-header-dark #page-header a.text-dual:focus,
.sidebar-dark #sidebar a.text-dual:hover,
.sidebar-dark #sidebar a.text-dual:focus {
  color: #c8ccd0 !important;
}

.bg-primary {
  background-color: #065E19 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #032e0c !important;
}

.bg-primary-op {
  background-color: rgba(6, 94, 25, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(3, 46, 12, 0.75) !important;
}

.bg-primary-dark {
  background-color: #3e4254 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #282b37 !important;
}

.bg-primary-dark-op {
  background-color: rgba(62, 66, 84, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(40, 43, 55, 0.8) !important;
}

.bg-primary-darker {
  background-color: #2c2e3b !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #16171e !important;
}

.bg-primary-light {
  background-color: #0ba62c !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #08761f !important;
}

.bg-primary-lighter {
  background-color: #0fe43d !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #0cb430 !important;
}

.bg-body {
  background-color: #f0f1f2 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #d5d8da !important;
}

.bg-body-light {
  background-color: #f8f9f9 !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #dddfe1 !important;
}

.bg-body-dark {
  background-color: #e4e6e7 !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #c8ccd0 !important;
}

.bg-header-light {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

a.bg-header-light:hover, a.bg-header-light:focus,
button.bg-header-light:hover,
button.bg-header-light:focus {
  background-color: rgba(230, 230, 230, 0.75) !important;
}

.bg-header-dark {
  background-color: #2c2e3b !important;
}

a.bg-header-dark:hover, a.bg-header-dark:focus,
button.bg-header-dark:hover,
button.bg-header-dark:focus {
  background-color: #16171e !important;
}

.bg-sidebar-light {
  background-color: #fff !important;
}

a.bg-sidebar-light:hover, a.bg-sidebar-light:focus,
button.bg-sidebar-light:hover,
button.bg-sidebar-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-sidebar-dark {
  background-color: #2c2e3b !important;
}

a.bg-sidebar-dark:hover, a.bg-sidebar-dark:focus,
button.bg-sidebar-dark:hover,
button.bg-sidebar-dark:focus {
  background-color: #16171e !important;
}

.btn-link {
  color: #065E19;
}
.btn-link:hover {
  color: black;
}

.btn-primary {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
}
.btn-primary:hover {
  color: #fff;
  background-color: #054b14;
  border-color: #054713;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #054b14;
  border-color: #054713;
  box-shadow: 0 0 0 0.25rem rgba(43, 118, 60, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #054b14;
  border-color: #054713;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 118, 60, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
}

.btn-secondary {
  color: #fff;
  background-color: #3e4254;
  border-color: #3e4254;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #323543;
  border-color: #2f323f;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #323543;
  border-color: #2f323f;
  box-shadow: 0 0 0 0.25rem rgba(91, 94, 110, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #323543;
  border-color: #2f323f;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(91, 94, 110, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #3e4254;
  border-color: #3e4254;
}

.btn-outline-primary {
  color: #065E19;
  border-color: #065E19;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #065E19;
  background-color: transparent;
}

.btn-alt-primary {
  color: #04380f;
  background-color: #c1d7c6;
  border-color: #c1d7c6;
}
.btn-alt-primary:hover {
  color: #02260a;
  background-color: #83af8c;
  border-color: #83af8c;
}
.btn-check:focus + .btn-alt-primary, .btn-alt-primary:focus {
  color: #02260a;
  background-color: #83af8c;
  border-color: #83af8c;
  box-shadow: 0 0 0 0.25rem rgba(165, 191, 171, 0.5);
}
.btn-check:checked + .btn-alt-primary, .btn-check:active + .btn-alt-primary, .btn-alt-primary:active, .btn-alt-primary.active, .show > .btn-alt-primary.dropdown-toggle {
  color: #000;
  background-color: #cddfd1;
  border-color: #c7dbcc;
}
.btn-check:checked + .btn-alt-primary:focus, .btn-check:active + .btn-alt-primary:focus, .btn-alt-primary:active:focus, .btn-alt-primary.active:focus, .show > .btn-alt-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(165, 191, 171, 0.5);
}
.btn-alt-primary:disabled, .btn-alt-primary.disabled {
  color: #000;
  background-color: #c1d7c6;
  border-color: #c1d7c6;
}

.btn-alt-secondary {
  color: #2c2e3b;
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}
.btn-alt-secondary:hover {
  color: #252732;
  background-color: #d8d9da;
  border-color: #d8d9da;
}
.btn-check:focus + .btn-alt-secondary, .btn-alt-secondary:focus {
  color: #252732;
  background-color: #d8d9da;
  border-color: #d8d9da;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 215, 0.5);
}
.btn-check:checked + .btn-alt-secondary, .btn-check:active + .btn-alt-secondary, .btn-alt-secondary:active, .btn-alt-secondary.active, .show > .btn-alt-secondary.dropdown-toggle {
  color: #000;
  background-color: #f3f4f5;
  border-color: #f2f2f3;
}
.btn-check:checked + .btn-alt-secondary:focus, .btn-check:active + .btn-alt-secondary:focus, .btn-alt-secondary:active:focus, .btn-alt-secondary.active:focus, .show > .btn-alt-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 215, 0.5);
}
.btn-alt-secondary:disabled, .btn-alt-secondary.disabled {
  color: #000;
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}

.page-header-dark #page-header .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary {
  color: #fff;
  background-color: #37394a;
  border-color: #37394a;
}
.page-header-dark #page-header .btn-alt-secondary:hover,
.sidebar-dark #sidebar .btn-alt-secondary:hover {
  color: #fff;
  background-color: #21232c;
  border-color: #21232c;
}
.btn-check:focus + .page-header-dark #page-header .btn-alt-secondary, .page-header-dark #page-header .btn-alt-secondary:focus,
.btn-check:focus + .sidebar-dark #sidebar .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary:focus {
  color: #fff;
  background-color: #21232c;
  border-color: #21232c;
  box-shadow: 0 0 0 0.25rem rgba(85, 87, 101, 0.5);
}
.btn-check:checked + .page-header-dark #page-header .btn-alt-secondary, .btn-check:active + .page-header-dark #page-header .btn-alt-secondary, .page-header-dark #page-header .btn-alt-secondary:active, .page-header-dark #page-header .btn-alt-secondary.active, .show > .page-header-dark #page-header .btn-alt-secondary.dropdown-toggle,
.btn-check:checked + .sidebar-dark #sidebar .btn-alt-secondary,
.btn-check:active + .sidebar-dark #sidebar .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary:active,
.sidebar-dark #sidebar .btn-alt-secondary.active,
.show > .sidebar-dark #sidebar .btn-alt-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c2e3b;
  border-color: #292b38;
}
.btn-check:checked + .page-header-dark #page-header .btn-alt-secondary:focus, .btn-check:active + .page-header-dark #page-header .btn-alt-secondary:focus, .page-header-dark #page-header .btn-alt-secondary:active:focus, .page-header-dark #page-header .btn-alt-secondary.active:focus, .show > .page-header-dark #page-header .btn-alt-secondary.dropdown-toggle:focus,
.btn-check:checked + .sidebar-dark #sidebar .btn-alt-secondary:focus,
.btn-check:active + .sidebar-dark #sidebar .btn-alt-secondary:focus,
.sidebar-dark #sidebar .btn-alt-secondary:active:focus,
.sidebar-dark #sidebar .btn-alt-secondary.active:focus,
.show > .sidebar-dark #sidebar .btn-alt-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(85, 87, 101, 0.5);
}
.page-header-dark #page-header .btn-alt-secondary:disabled, .page-header-dark #page-header .btn-alt-secondary.disabled,
.sidebar-dark #sidebar .btn-alt-secondary:disabled,
.sidebar-dark #sidebar .btn-alt-secondary.disabled {
  color: #fff;
  background-color: #37394a;
  border-color: #37394a;
}

.alert-primary {
  color: #3e4254;
  background-color: #0fe43d;
  border-color: #0fe43d;
  box-shadow: 0 0.125rem #0dcc36;
}
.alert-primary hr {
  border-top-color: #0dcc36;
}
.alert-primary .alert-link {
  color: #282b37;
}

.progress-bar {
  background-color: #065E19;
}

.nav-link {
  color: #3e4254;
}
.nav-link:hover, .nav-link:focus {
  color: #065E19;
}

.nav-pills .nav-link {
  color: #3e4254;
}
.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  background-color: #f0f1f2;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #065E19;
}

.nav-tabs {
  border-bottom-color: #e4e6e7;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e4e6e7 #e4e6e7 #e4e6e7;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #e4e6e7 #e4e6e7 #fff;
}

.nav-tabs-block {
  background-color: #f8f9f9;
}
.nav-tabs-block .nav-link {
  border-color: transparent;
  color: #3e4254;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #065E19;
  background-color: #f8f9f9;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #3e4254;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  border-bottom-color: #e4e6e7;
}
.nav-tabs-alt .nav-link {
  color: #3e4254;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #065E19;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #065E19;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  color: #3e4254;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #065E19;
}

.nav-items a {
  border-bottom-color: #f0f1f2;
}
.nav-items a:hover {
  background-color: #f8f9f9;
}
.nav-items a:active {
  background-color: #f0f1f2;
}
.nav-items > li:last-child > a {
  border-bottom: none;
}

.card.card-borderless {
  box-shadow: 0 1px 2px rgba(221, 223, 224, 0.5), 0 1px 2px rgba(221, 223, 224, 0.5);
}
.card > .card-header:not(.bg-transparent),
.card > .card-footer:not(.bg-transparent) {
  background-color: #f8f9f9;
}
.card > .card-header:not(.border-bottom-0),
.card > .card-footer:not(.border-top-0) {
  border-color: #e4e6e7;
}
.card:not(.card-borderless),
.card:not(.card-borderless) > .card-header {
  border-color: #e4e6e7;
}

.page-item.active .page-link {
  color: #065E19;
  background-color: #f8f9f9;
  border-color: #065E19;
}

.page-link {
  color: #3e4254;
}
.page-link:hover {
  color: #065E19;
  border-color: #065E19;
}
.page-link:focus {
  background-color: #f0f1f2;
}

.list-group-item-action {
  color: #3e4254;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #3e4254;
  background-color: #f8f9f9;
}
.list-group-item-action:active {
  color: #3e4254;
  background-color: #e4e6e7;
}

.list-group-item {
  border-color: #e4e6e7;
}
.list-group-item.active {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
}

.popover {
  border-color: #e4e6e7;
}

.bs-popover-top .popover-arrow::before, .bs-popover-auto[x-placement^=top] .popover-arrow::before {
  border-top-color: #e4e6e7;
}
.bs-popover-top .popover-arrow::after, .bs-popover-auto[x-placement^=top] .popover-arrow::after {
  border-top-color: #fff;
}

.bs-popover-end .popover-arrow::before, .bs-popover-auto[x-placement^=right] .popover-arrow::before {
  border-right-color: #e4e6e7;
}
.bs-popover-end .popover-arrow::after, .bs-popover-auto[x-placement^=right] .popover-arrow::after {
  border-right-color: #fff;
}

.bs-popover-bottom .popover-arrow::before, .bs-popover-auto[x-placement^=bottom] .popover-arrow::before {
  border-bottom-color: #e4e6e7;
}
.bs-popover-bottom .popover-arrow::after, .bs-popover-auto[x-placement^=bottom] .popover-arrow::after {
  border-bottom-color: #fff;
}

.bs-popover-start .popover-arrow::before, .bs-popover-auto[x-placement^=left] .popover-arrow::before {
  border-left-color: #e4e6e7;
}
.bs-popover-start .popover-arrow::after, .bs-popover-auto[x-placement^=left] .popover-arrow::after {
  border-left-color: #fff;
}

.modal-header {
  border-bottom-color: #e4e6e7;
}

.modal-footer {
  border-top-color: #e4e6e7;
}

.dropdown-menu {
  border-color: #e4e6e7;
}

.dropdown-divider {
  border-top-color: #f0f1f2;
}

.dropdown-item {
  color: #3e4254;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #2c2e3b;
  background-color: #f0f1f2;
}
.dropdown-item.active, .dropdown-item:active {
  color: #3e4254;
  background-color: #e4e6e7;
}

.table {
  --bs-table-striped-bg: #f4f5f6;
  --bs-table-active-bg: #f0f1f2;
  --bs-table-hover-bg: #ecedee;
  border-color: #e4e6e7;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e4e6e7;
}

.table-primary {
  --bs-table-bg: #0fe43d;
  --bs-table-striped-bg: #0ed93a;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #0ecd37;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #0ed338;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #0ecd37;
}

.form-control,
.form-select {
  color: #3e4254;
  background-color: #fff;
  border-color: #dadddf;
}
.form-control:focus,
.form-select:focus {
  color: #2c2e3b;
  background-color: #fff;
  border-color: #0ed639;
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.25);
}
.form-control:disabled, .form-control[readonly],
.form-select:disabled,
.form-select[readonly] {
  background-color: #e9ecef;
}

.form-select:focus::-ms-value {
  color: #3e4254;
  background-color: #fff;
}

.form-control.form-control-alt,
.form-select.form-control-alt {
  border-color: #f0f1f2;
  background-color: #f0f1f2;
}
.form-control.form-control-alt:focus,
.form-select.form-control-alt:focus {
  border-color: #e4e6e7;
  background-color: #e4e6e7;
  box-shadow: none;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #65a30d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(101, 163, 13, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #65a30d;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #65a30d;
  box-shadow: 0 0 0 0.25rem rgba(101, 163, 13, 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #65a30d;
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #65a30d;
  box-shadow: 0 0 0 0.25rem rgba(101, 163, 13, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #65a30d;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #65a30d;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(101, 163, 13, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #65a30d;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #dc2626;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 38, 38, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc2626;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc2626;
  box-shadow: 0 0 0 0.25rem rgba(220, 38, 38, 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc2626;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc2626;
  box-shadow: 0 0 0 0.25rem rgba(220, 38, 38, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc2626;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc2626;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 38, 38, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc2626;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.input-group-text {
  color: #3e4254;
  background-color: #f0f1f2;
  border-color: #dadddf;
}

.input-group-text.input-group-text-alt {
  background-color: #e4e6e7;
  border-color: #e4e6e7;
}

.form-check-input {
  border-color: #d5d8da;
}
.form-check-input:focus {
  border-color: #065E19;
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.25);
}
.form-check-input:checked {
  background-color: #065E19;
  border-color: #065E19;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d5d8da'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23065E19'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-block .form-check-label {
  border: 2px solid #e4e6e7;
}
.form-block .form-check-label:hover {
  border-color: #d6d9dc;
}
.form-block .form-check-label::before {
  background-color: #065E19;
}
.form-block .form-check-input:checked ~ .form-check-label {
  border-color: #065E19;
}
.form-block .form-check-input:focus ~ .form-check-label {
  border-color: #065E19;
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.25);
}
.form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #e4e6e7;
}

.border {
  border-color: #e4e6e7 !important;
}

.border-top {
  border-top-color: #e4e6e7 !important;
}

.border-end {
  border-right-color: #e4e6e7 !important;
}

.border-bottom {
  border-bottom-color: #e4e6e7 !important;
}

.border-start {
  border-left-color: #e4e6e7 !important;
}

.border-primary {
  border-color: #4c78dd !important;
}

.border-secondary {
  border-color: #475569 !important;
}

.border-success {
  border-color: #65a30d !important;
}

.border-info {
  border-color: #0891b2 !important;
}

.border-warning {
  border-color: #ea580c !important;
}

.border-danger {
  border-color: #dc2626 !important;
}

.border-light {
  border-color: #ebeef2 !important;
}

.border-dark {
  border-color: #334155 !important;
}

.border-primary {
  border-color: #065E19 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

#page-header {
  background-color: rgba(255, 255, 255, 0.75);
}

#sidebar {
  background-color: #fff;
}

#side-overlay {
  background-color: #fff;
}

#page-container.page-header-dark #page-header {
  color: #ced1d4;
  background-color: #2c2e3b;
}
#page-container.page-header-glass #page-header {
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: rgba(255, 255, 255, 0.75);
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-dark #page-header {
  background-color: #2c2e3b;
}
#page-container #sidebar .content-header {
  background-color: #f8f9f9;
}
#page-container.sidebar-dark #sidebar {
  color: #e4e6e7;
  background-color: #2c2e3b;
}
#page-container.sidebar-dark #sidebar .content-header {
  background-color: #303341;
}

#sidebar.with-mini-nav .sidebar-mini-nav {
  color: #e4e6e7;
  background-color: #333544;
}

.block-header-default {
  background-color: #f8f9f9;
}

.block {
  box-shadow: 0 1px 2px rgba(221, 223, 224, 0.5), 0 1px 2px rgba(221, 223, 224, 0.5);
}
.block.block-bordered {
  border-color: #e4e6e7;
}
.block.block-themed > .block-header {
  background-color: #065E19;
}

.block.block-mode-loading::after {
  color: #3e4254;
}
.block.block-mode-loading.block-mode-loading-dark::after {
  background-color: #3e4254;
}

a.block {
  color: #3e4254;
}
a.block:hover {
  color: #3e4254;
}
a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2rem #dadddf;
}
a.block.block-link-pop:active {
  box-shadow: 0 0.25rem 0.75rem #edeef0;
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 2.25rem #dadddf;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 1.125rem #e5e7e9;
}

.block.block-fx-shadow {
  box-shadow: 0 0 2.25rem #dadddf;
}
.block.block-fx-pop {
  box-shadow: 0 0.5rem 2rem #dadddf;
}

#page-loader::after {
  background-color: #065E19;
}

.nav-main-heading {
  color: #69708f;
}

.nav-main-link {
  color: #3e4254;
}
.nav-main-link .nav-main-link-icon {
  color: #9499b0;
}
.nav-main-link:hover {
  color: #3e4254;
  background-color: #f8f9f9;
}
.nav-main-link:hover > .nav-main-link-icon {
  color: #000;
}
.nav-main-link.active {
  color: #000;
}
.nav-main-link.active > .nav-main-link-icon {
  color: #000;
}

.nav-main-submenu .nav-main-link {
  color: #545971;
}
.nav-main-submenu .nav-main-link:hover, .nav-main-submenu .nav-main-link.active {
  color: #000;
  background-color: transparent;
}

.nav-main-item.open > .nav-main-link-submenu {
  color: #000;
}

.nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

.nav-main-dark .nav-main-heading,
.sidebar-dark #sidebar .nav-main-heading,
.page-header-dark #page-header .nav-main-heading {
  color: #848c94;
}
.nav-main-dark .nav-main-link,
.sidebar-dark #sidebar .nav-main-link,
.page-header-dark #page-header .nav-main-link {
  color: #adb3b8;
}
.nav-main-dark .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link > .nav-main-link-icon {
  color: rgba(173, 179, 184, 0.5);
}
.nav-main-dark .nav-main-link:hover, .nav-main-dark .nav-main-link:focus,
.sidebar-dark #sidebar .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-link:focus,
.page-header-dark #page-header .nav-main-link:hover,
.page-header-dark #page-header .nav-main-link:focus {
  color: #9fa6ac;
}
.nav-main-dark .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-submenu,
.page-header-dark #page-header .nav-main-submenu {
  background-color: rgba(0, 0, 0, 0.15);
}
.nav-main-dark .nav-main-submenu .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-link {
  color: #9299a0;
}
.nav-main-dark .nav-main-submenu .nav-main-link:hover, .nav-main-dark .nav-main-submenu .nav-main-link:focus, .nav-main-dark .nav-main-submenu .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:focus,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link.active,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:hover,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:focus,
.page-header-dark #page-header .nav-main-submenu .nav-main-link.active {
  color: #fff;
  background-color: transparent;
}
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu,
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon {
  color: #fff;
}
.nav-main-dark .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-submenu {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-horizontal .nav-main-submenu {
    background-color: #f0f1f2;
  }
  .nav-main-dark.nav-main-horizontal .nav-main-submenu,
  .sidebar-dark #sidebar .nav-main-horizontal .nav-main-submenu,
  .page-header-dark #page-header .nav-main-horizontal .nav-main-submenu {
    background-color: #252732 !important;
  }
}
.nav-items a {
  border-bottom-color: #f0f1f2;
}
.nav-items a:hover {
  background-color: #f8f9f9;
}

.mini-nav-item {
  color: #bbbfc4;
}
.mini-nav-item.active {
  background-color: #393c4d;
  color: #fff;
}
.mini-nav-item:hover {
  color: #fff;
  background-color: #393c4d;
}
.mini-nav-item:active {
  color: #bbbfc4;
}

.list-activity > li {
  border-bottom-color: #f0f1f2;
}

.timeline-event-icon {
  box-shadow: 0 0.375rem 1.5rem #dadddf;
}

.ribbon-light .ribbon-box {
  color: #3e4254;
  background-color: #e4e6e7;
}
.ribbon-light.ribbon-bookmark .ribbon-box::before {
  border-color: #e4e6e7;
  border-left-color: transparent;
}
.ribbon-light.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #e4e6e7;
  border-right-color: transparent;
}
.ribbon-light.ribbon-modern .ribbon-box::before {
  border-color: #e4e6e7;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-light.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #e4e6e7;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-light.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #e4e6e7;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-light.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #e4e6e7;
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-primary .ribbon-box {
  color: #fff;
  background-color: #065E19;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #065E19;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #065E19;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-modern .ribbon-box::before {
  border-color: #065E19;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #065E19;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #065E19;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #065E19;
  border-top-color: transparent;
  border-right-color: transparent;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #065E19;
  border-color: #065E19;
}

.cke_chrome,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #e4e6e7 !important;
}

.cke_top,
.ck.ck-toolbar {
  border-bottom-color: #e4e6e7 !important;
  background: #f8f9f9 !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #e4e6e7 !important;
}

.cke_bottom {
  border-top-color: #e4e6e7 !important;
  background: #f8f9f9 !important;
}

.dropzone {
  background-color: #f8f9f9;
  border-color: #dadddf;
}
.dropzone .dz-message {
  color: #3e4254;
}
.dropzone:hover {
  background-color: #fff;
  border-color: #065E19;
}
.dropzone:hover .dz-message {
  color: #065E19;
}

.fc-theme-standard a {
  color: #3e4254;
}
.fc-theme-standard .fc-button-primary {
  color: #3e4254;
  background-color: #e4e6e7;
  border-color: #e4e6e7;
}
.fc-theme-standard .fc-button-primary:not(:disabled):hover {
  color: #3e4254;
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}
.fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active, .fc-theme-standard .fc-button-primary:not(:disabled):active {
  color: #3e4254;
  background-color: #f8f9f9;
  border-color: #f8f9f9;
}
.fc-theme-standard .fc-button-primary:focus, .fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active:focus, .fc-theme-standard .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(6, 94, 25, 0.4);
}
.fc-theme-standard th,
.fc-theme-standard td,
.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard .fc-list {
  border-color: #e4e6e7;
}
.fc-theme-standard .fc-h-event {
  background-color: #065E19;
  border: #065E19;
}
.fc-theme-standard .fc-col-header-cell,
.fc-theme-standard .fc-list-day-cushion {
  background-color: #f8f9f9;
}

.irs.irs--round .irs-min,
.irs.irs--round .irs-max,
.irs.irs--round .irs-line,
.irs.irs--round .irs-grid-pol {
  background: #f0f1f2;
}
.irs.irs--round .irs-handle {
  border-color: #065E19;
}
.irs.irs--round .irs-from:before,
.irs.irs--round .irs-to:before,
.irs.irs--round .irs-single:before {
  border-top-color: #065E19;
}
.irs.irs--round .irs-bar,
.irs.irs--round .irs-from,
.irs.irs--round .irs-to,
.irs.irs--round .irs-single {
  background: #065E19;
}

.select2-container--default .select2-selection--single {
  border-color: #dadddf;
}
.select2-container--default .select2-selection--multiple {
  border-color: #dadddf;
}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--multiple, .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #0ed639;
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.25);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #065E19;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #dadddf;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #065E19;
}
.select2-container--default .select2-dropdown .select2-search__field:focus {
  border-color: #0ed639;
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.25);
}

.simplebar-scrollbar::before {
  background: #2c2e3b;
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  color: #3e4254;
}

.editor-toolbar {
  border-color: #e4e6e7;
  background-color: #f8f9f9;
}

.CodeMirror {
  border-color: #e4e6e7;
}

.flatpickr-input.form-control:disabled,
.flatpickr-input.form-control[readonly],
.input.form-control:disabled,
.input.form-control[readonly] {
  color: #3e4254;
  background-color: #fff;
  border-color: #dadddf;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: #065E19;
  background: #065E19;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #065E19;
}

.jvectormap-tip {
  background: #3e4254;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  background: #3e4254;
}

.dark-mode,
.dark-mode #side-overlay,
.dark-mode #page-loader {
  background-color: #252732;
  color: #c8ccd0;
}
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode .h1,
.dark-mode .h2,
.dark-mode .h3,
.dark-mode .h4,
.dark-mode .h5,
.dark-mode .h6 {
  color: #e4e6e7;
}
.dark-mode .content-heading,
.dark-mode .content-heading small {
  color: #a6acb2;
}
.dark-mode hr {
  border-top-color: #393c4d;
}
.dark-mode code {
  color: #e685b5;
}
.dark-mode .story p,
.dark-mode p.story {
  color: #adb3b8;
}
.dark-mode a:not(.mini-nav-item):not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light) {
  color: #08761f;
}
.dark-mode a:not(.mini-nav-item):not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light).link-fx::before {
  background-color: #08761f;
}
.dark-mode a:not(.mini-nav-item):not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light):hover {
  color: #065E19;
}
.dark-mode .bg-body {
  background-color: #252732 !important;
}
.dark-mode a.bg-body:hover, .dark-mode a.bg-body:focus,
.dark-mode button.bg-body:hover,
.dark-mode button.bg-body:focus {
  background-color: #101015 !important;
}
.dark-mode .bg-body-light {
  background-color: #333544 !important;
}
.dark-mode a.bg-body-light:hover, .dark-mode a.bg-body-light:focus,
.dark-mode button.bg-body-light:hover,
.dark-mode button.bg-body-light:focus {
  background-color: #1d1e27 !important;
}
.dark-mode .bg-body-dark {
  background-color: #22242e !important;
}
.dark-mode a.bg-body-dark:hover, .dark-mode a.bg-body-dark:focus,
.dark-mode button.bg-body-dark:hover,
.dark-mode button.bg-body-dark:focus {
  background-color: #0c0d11 !important;
}
.dark-mode .bg-body-extra-light {
  background-color: #2c2e3b !important;
}
.dark-mode a.bg-body-extra-light:hover, .dark-mode a.bg-body-extra-light:focus,
.dark-mode button.bg-body-extra-light:hover,
.dark-mode button.bg-body-extra-light:focus {
  background-color: #16171e !important;
}
.dark-mode .bg-muted {
  background-color: #a6acb2 !important;
}
.dark-mode a.bg-muted:hover, .dark-mode a.bg-muted:focus,
.dark-mode button.bg-muted:hover,
.dark-mode button.bg-muted:focus {
  background-color: #8b939a !important;
}
.dark-mode .text-primary {
  color: #08761f !important;
}
.dark-mode a.text-primary.link-fx::before {
  background-color: #08761f !important;
}
.dark-mode a.text-primary:hover, .dark-mode a.text-primary:focus {
  color: #044613 !important;
}
.dark-mode .text-success {
  color: #74bb0f !important;
}
.dark-mode a.text-success.link-fx::before {
  background-color: #74bb0f !important;
}
.dark-mode a.text-success:hover, .dark-mode a.text-success:focus {
  color: #568b0b !important;
}
.dark-mode .text-warning {
  color: #f3661c !important;
}
.dark-mode a.text-warning.link-fx::before {
  background-color: #f3661c !important;
}
.dark-mode a.text-warning:hover, .dark-mode a.text-warning:focus {
  color: #d24f0b !important;
}
.dark-mode .text-info {
  color: #09a5ca !important;
}
.dark-mode a.text-info.link-fx::before {
  background-color: #09a5ca !important;
}
.dark-mode a.text-info:hover, .dark-mode a.text-info:focus {
  color: #077d9a !important;
}
.dark-mode .text-danger {
  color: #e03c3c !important;
}
.dark-mode a.text-danger.link-fx::before {
  background-color: #e03c3c !important;
}
.dark-mode a.text-danger:hover, .dark-mode a.text-danger:focus {
  color: #c82020 !important;
}
.dark-mode .text-body-bg {
  color: #252732 !important;
}
.dark-mode a.text-body-bg.link-fx::before {
  background-color: #252732 !important;
}
.dark-mode a.text-body-bg:hover, .dark-mode a.text-body-bg:focus {
  color: #101015 !important;
}
.dark-mode .text-body-bg-dark {
  color: #1f2029 !important;
}
.dark-mode a.text-body-bg-dark.link-fx::before {
  background-color: #1f2029 !important;
}
.dark-mode a.text-body-bg-dark:hover, .dark-mode a.text-body-bg-dark:focus {
  color: #090a0c !important;
}
.dark-mode .text-body-bg-light {
  color: #333544 !important;
}
.dark-mode a.text-body-bg-light.link-fx::before {
  background-color: #333544 !important;
}
.dark-mode a.text-body-bg-light:hover, .dark-mode a.text-body-bg-light:focus {
  color: #1d1e27 !important;
}
.dark-mode .text-body-color {
  color: #c8ccd0 !important;
}
.dark-mode a.text-body-color.link-fx::before {
  background-color: #c8ccd0 !important;
}
.dark-mode a.text-body-color:hover, .dark-mode a.text-body-color:focus {
  color: #adb3b8 !important;
}
.dark-mode .text-body-color-dark {
  color: #adb3b8 !important;
}
.dark-mode a.text-body-color-dark.link-fx::before {
  background-color: #adb3b8 !important;
}
.dark-mode a.text-body-color-dark:hover, .dark-mode a.text-body-color-dark:focus {
  color: #9299a0 !important;
}
.dark-mode .text-body-color-light {
  color: #e4e6e7 !important;
}
.dark-mode a.text-body-color-light.link-fx::before {
  background-color: #e4e6e7 !important;
}
.dark-mode a.text-body-color-light:hover, .dark-mode a.text-body-color-light:focus {
  color: #c8ccd0 !important;
}
.dark-mode .text-dark {
  color: #adb3b8 !important;
}
.dark-mode a.text-dark.link-fx::before {
  background-color: #adb3b8 !important;
}
.dark-mode a.text-dark:hover, .dark-mode a.text-dark:focus {
  color: #9299a0 !important;
}
.dark-mode .text-muted {
  color: #a6acb2 !important;
}
.dark-mode a.text-muted.link-fx::before {
  background-color: #a6acb2 !important;
}
.dark-mode a.text-muted:hover, .dark-mode a.text-muted:focus {
  color: #8b939a !important;
}
.dark-mode .btn-secondary,
.dark-mode .btn-alt-secondary {
  color: #fff;
  background-color: #37394a;
  border-color: #37394a;
}
.dark-mode .btn-secondary:hover,
.dark-mode .btn-alt-secondary:hover {
  color: #fff;
  background-color: #21232c;
  border-color: #21232c;
}
.btn-check:focus + .dark-mode .btn-secondary, .dark-mode .btn-secondary:focus,
.btn-check:focus + .dark-mode .btn-alt-secondary,
.dark-mode .btn-alt-secondary:focus {
  color: #fff;
  background-color: #21232c;
  border-color: #21232c;
  box-shadow: 0 0 0 0.25rem rgba(85, 87, 101, 0.5);
}
.btn-check:checked + .dark-mode .btn-secondary, .btn-check:active + .dark-mode .btn-secondary, .dark-mode .btn-secondary:active, .dark-mode .btn-secondary.active, .show > .dark-mode .btn-secondary.dropdown-toggle,
.btn-check:checked + .dark-mode .btn-alt-secondary,
.btn-check:active + .dark-mode .btn-alt-secondary,
.dark-mode .btn-alt-secondary:active,
.dark-mode .btn-alt-secondary.active,
.show > .dark-mode .btn-alt-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c2e3b;
  border-color: #292b38;
}
.btn-check:checked + .dark-mode .btn-secondary:focus, .btn-check:active + .dark-mode .btn-secondary:focus, .dark-mode .btn-secondary:active:focus, .dark-mode .btn-secondary.active:focus, .show > .dark-mode .btn-secondary.dropdown-toggle:focus,
.btn-check:checked + .dark-mode .btn-alt-secondary:focus,
.btn-check:active + .dark-mode .btn-alt-secondary:focus,
.dark-mode .btn-alt-secondary:active:focus,
.dark-mode .btn-alt-secondary.active:focus,
.show > .dark-mode .btn-alt-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(85, 87, 101, 0.5);
}
.dark-mode .btn-secondary:disabled, .dark-mode .btn-secondary.disabled,
.dark-mode .btn-alt-secondary:disabled,
.dark-mode .btn-alt-secondary.disabled {
  color: #fff;
  background-color: #37394a;
  border-color: #37394a;
}
.dark-mode .nav-link {
  color: #c8ccd0;
}
.dark-mode .nav-link:hover, .dark-mode .nav-link:focus {
  color: #065E19;
}
.dark-mode .nav-pills .nav-link {
  color: #9299a0;
}
.dark-mode .nav-pills .nav-link:hover, .dark-mode .nav-pills .nav-link:focus {
  background-color: #3d4052;
}
.dark-mode .nav-pills .nav-link.active,
.dark-mode .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #065E19;
}
.dark-mode .nav-tabs {
  border-bottom-color: #393c4d;
}
.dark-mode .nav-tabs .nav-link:hover, .dark-mode .nav-tabs .nav-link:focus {
  border-color: #393c4d #393c4d #393c4d;
}
.dark-mode .nav-tabs .nav-link.active,
.dark-mode .nav-tabs .nav-item.show .nav-link {
  color: #c8ccd0;
  background-color: transparent;
  border-color: #393c4d #393c4d #2c2e3b;
}
.dark-mode .nav-tabs-block {
  background-color: #393c4d;
}
.dark-mode .nav-tabs-block .nav-link {
  border-color: transparent;
  color: #c8ccd0;
}
.dark-mode .nav-tabs-block .nav-link:hover, .dark-mode .nav-tabs-block .nav-link:focus {
  color: #065E19;
  background-color: #333544;
  border-color: transparent;
}
.dark-mode .nav-tabs-block .nav-link.active,
.dark-mode .nav-tabs-block .nav-item.show .nav-link {
  color: #c8ccd0;
  background-color: #2c2e3b;
  border-color: transparent;
}
.dark-mode .nav-tabs-alt {
  border-bottom-color: #333544;
}
.dark-mode .nav-tabs-alt .nav-link {
  color: #c8ccd0;
  background-color: transparent;
  border-color: transparent;
}
.dark-mode .nav-tabs-alt .nav-link:hover, .dark-mode .nav-tabs-alt .nav-link:focus {
  color: #065E19;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #065E19;
}
.dark-mode .nav-tabs-alt .nav-link.active,
.dark-mode .nav-tabs-alt .nav-item.show .nav-link {
  color: #c8ccd0;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #065E19;
}
.dark-mode .nav-items a:hover {
  background-color: #3d4052;
}
.dark-mode .nav-items a:active {
  background-color: transparent;
}
.dark-mode .nav-items > li:not(:last-child) > a {
  border-bottom-color: #393c4d;
}
.dark-mode .card {
  background-color: #2c2e3b;
}
.dark-mode .card.card-borderless {
  box-shadow: 0 1px 2px rgba(35, 37, 47, 0.5), 0 1px 2px rgba(35, 37, 47, 0.5);
}
.dark-mode .card > .card-header:not(.bg-transparent),
.dark-mode .card > .card-footer:not(.bg-transparent) {
  background-color: #393c4d;
}
.dark-mode .card > .card-header:not(.border-bottom-0),
.dark-mode .card > .card-footer:not(.border-top-0) {
  border-color: #393c4d;
}
.dark-mode .card:not(.card-borderless),
.dark-mode .card:not(.card-borderless) > .card-header {
  border-color: #393c4d;
}
.dark-mode .card > .card-header .block-title small {
  color: #a6acb2;
}
.dark-mode .page-link {
  color: #9299a0;
}
.dark-mode .page-link:hover {
  color: #adb3b8;
}
.dark-mode .page-link:focus {
  color: #c8ccd0;
  background-color: transparent;
}
.dark-mode .page-item.active .page-link {
  background-color: transparent;
}
.dark-mode .page-item.disabled .page-link {
  color: #5f666d;
  background-color: transparent;
  border-bottom: transparent;
}
.dark-mode .list-group-item-action {
  color: #c8ccd0;
}
.dark-mode .list-group-item-action:hover, .dark-mode .list-group-item-action:focus {
  color: #c8ccd0;
  background-color: #363848;
}
.dark-mode .list-group-item-action:active {
  color: #c8ccd0;
  background-color: #252732;
}
.dark-mode .list-group-item-action.disabled {
  color: #768089;
}
.dark-mode .list-group-item {
  color: #c8ccd0;
  background-color: #333544;
  border-color: #22242e;
}
.dark-mode .list-group-item.active {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
}
.dark-mode .popover {
  border-color: #22242e;
  background-color: #333544;
}
.dark-mode .bs-popover-top .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=top] .popover-arrow::before {
  border-top-color: #22242e;
}
.dark-mode .bs-popover-top .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=top] .popover-arrow::after {
  border-top-color: #333544;
}
.dark-mode .bs-popover-end .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=right] .popover-arrow::before {
  border-right-color: #22242e;
}
.dark-mode .bs-popover-end .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=right] .popover-arrow::after {
  border-right-color: #333544;
}
.dark-mode .bs-popover-bottom .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-arrow::before {
  border-bottom-color: #22242e;
}
.dark-mode .bs-popover-bottom .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-arrow::after {
  border-bottom-color: #333544;
}
.dark-mode .bs-popover-start .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=left] .popover-arrow::before {
  border-left-color: #22242e;
}
.dark-mode .bs-popover-start .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=left] .popover-arrow::after {
  border-left-color: #333544;
}
.dark-mode .popover-header {
  color: #e4e6e7;
  background-color: #333544;
  border-bottom-color: #22242e;
}
.dark-mode .popover-body {
  color: #c8ccd0;
  background-color: #333544;
}
.dark-mode .dropdown-menu {
  color: #c8ccd0;
  background-color: #333544;
  border-color: #333544;
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.25);
}
.dark-mode .dropdown-menu .dropdown-item {
  color: #bbbfc4;
}
.dark-mode .dropdown-menu .dropdown-item:hover, .dark-mode .dropdown-menu .dropdown-item:focus {
  color: #c8ccd0;
  background-color: #3d4052;
}
.dark-mode .dropdown-menu .dropdown-item.active, .dark-mode .dropdown-menu .dropdown-item:active {
  color: #d6d9dc;
  background-color: #46495e;
}
.dark-mode .dropdown-menu .dropdown-item.disabled, .dark-mode .dropdown-menu .dropdown-item:disabled {
  color: #5f666d;
}
.dark-mode .dropdown-menu .dropdown-divider {
  border-color: #424558;
}
.dark-mode .dropdown-menu .dropdown-item-text {
  color: #c8ccd0;
}
.dark-mode .dropdown-menu .dropdown-header {
  color: #9fa6ac !important;
}
.dark-mode .table {
  --bs-table-striped-color: #f8f9f9;
  --bs-table-striped-bg: #292b37;
  --bs-table-active-color: #f8f9f9;
  --bs-table-active-bg: #393c4d;
  --bs-table-hover-color: #f8f9f9;
  --bs-table-hover-bg: #272834;
  color: #f8f9f9;
  border-color: #1f2029;
}
.dark-mode .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #1f2029;
}
.dark-mode .table-primary {
  --bs-table-bg: rgba(5, 75, 20, 0.75);
  --bs-table-striped-bg: rgba(25, 90, 39, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(44, 103, 57, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(35, 96, 48, 0.76875);
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: rgba(44, 103, 57, 0.775);
}
.dark-mode .table-info {
  --bs-table-bg: rgba(30, 58, 138, 0.75);
  --bs-table-striped-bg: rgba(48, 74, 147, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(65, 89, 156, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(57, 81, 152, 0.76875);
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: rgba(65, 89, 156, 0.775);
}
.dark-mode .table-success {
  --bs-table-bg: rgba(20, 83, 45, 0.75);
  --bs-table-striped-bg: rgba(39, 97, 62, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(57, 110, 78, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(48, 103, 70, 0.76875);
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: rgba(57, 110, 78, 0.775);
}
.dark-mode .table-danger {
  --bs-table-bg: rgba(127, 29, 29, 0.75);
  --bs-table-striped-bg: rgba(137, 47, 47, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(147, 64, 64, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(142, 56, 56, 0.76875);
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: rgba(147, 64, 64, 0.775);
}
.dark-mode .table-warning {
  --bs-table-bg: rgba(113, 63, 18, 0.75);
  --bs-table-striped-bg: rgba(124, 78, 37, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(135, 93, 55, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(130, 86, 46, 0.76875);
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: rgba(135, 93, 55, 0.775);
}
.dark-mode .form-text {
  color: #9299a0;
}
.dark-mode .form-control::placeholder {
  color: #768089;
}
.dark-mode .form-floating > .form-control::placeholder {
  color: transparent;
}
.dark-mode .form-control,
.dark-mode .form-select {
  color: #c8ccd0;
  background-color: #22242e;
  border-color: #3d4052;
}
.dark-mode .form-control:focus,
.dark-mode .form-select:focus {
  color: #fff;
  background-color: #22242e;
  border-color: #065E19;
}
.dark-mode .form-control:disabled, .dark-mode .form-control[readonly],
.dark-mode .form-select:disabled,
.dark-mode .form-select[readonly] {
  background-color: #303341;
  border-color: #303341;
}
.dark-mode .form-select:focus::-ms-value {
  color: #c8ccd0;
  background-color: #22242e;
}
.dark-mode .form-control-plaintext {
  color: #c8ccd0;
}
.dark-mode .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #82d211;
}
.dark-mode .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(130, 210, 17, 0.9);
  border-radius: 0.25rem;
}
.was-validated .dark-mode:valid ~ .valid-feedback,
.was-validated .dark-mode:valid ~ .valid-tooltip, .dark-mode.is-valid ~ .valid-feedback,
.dark-mode.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .dark-mode .form-control:valid, .dark-mode .form-control.is-valid {
  border-color: #82d211;
}
.was-validated .dark-mode .form-control:valid:focus, .dark-mode .form-control.is-valid:focus {
  border-color: #82d211;
  box-shadow: 0 0 0 0.25rem rgba(130, 210, 17, 0.25);
}
.was-validated .dark-mode .form-select:valid, .dark-mode .form-select.is-valid {
  border-color: #82d211;
}
.was-validated .dark-mode .form-select:valid:focus, .dark-mode .form-select.is-valid:focus {
  border-color: #82d211;
  box-shadow: 0 0 0 0.25rem rgba(130, 210, 17, 0.25);
}
.was-validated .dark-mode .form-check-input:valid, .dark-mode .form-check-input.is-valid {
  border-color: #82d211;
}
.was-validated .dark-mode .form-check-input:valid:checked, .dark-mode .form-check-input.is-valid:checked {
  background-color: #82d211;
}
.was-validated .dark-mode .form-check-input:valid:focus, .dark-mode .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 210, 17, 0.25);
}
.was-validated .dark-mode .form-check-input:valid ~ .form-check-label, .dark-mode .form-check-input.is-valid ~ .form-check-label {
  color: #82d211;
}
.dark-mode .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .dark-mode .input-group .form-control:valid, .dark-mode .input-group .form-control.is-valid,
.was-validated .dark-mode .input-group .form-select:valid,
.dark-mode .input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .dark-mode .input-group .form-control:valid:focus, .dark-mode .input-group .form-control.is-valid:focus,
.was-validated .dark-mode .input-group .form-select:valid:focus,
.dark-mode .input-group .form-select.is-valid:focus {
  z-index: 3;
}
.dark-mode .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #e76868;
}
.dark-mode .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(231, 104, 104, 0.9);
  border-radius: 0.25rem;
}
.was-validated .dark-mode:invalid ~ .invalid-feedback,
.was-validated .dark-mode:invalid ~ .invalid-tooltip, .dark-mode.is-invalid ~ .invalid-feedback,
.dark-mode.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .dark-mode .form-control:invalid, .dark-mode .form-control.is-invalid {
  border-color: #e76868;
}
.was-validated .dark-mode .form-control:invalid:focus, .dark-mode .form-control.is-invalid:focus {
  border-color: #e76868;
  box-shadow: 0 0 0 0.25rem rgba(231, 104, 104, 0.25);
}
.was-validated .dark-mode .form-select:invalid, .dark-mode .form-select.is-invalid {
  border-color: #e76868;
}
.was-validated .dark-mode .form-select:invalid:focus, .dark-mode .form-select.is-invalid:focus {
  border-color: #e76868;
  box-shadow: 0 0 0 0.25rem rgba(231, 104, 104, 0.25);
}
.was-validated .dark-mode .form-check-input:invalid, .dark-mode .form-check-input.is-invalid {
  border-color: #e76868;
}
.was-validated .dark-mode .form-check-input:invalid:checked, .dark-mode .form-check-input.is-invalid:checked {
  background-color: #e76868;
}
.was-validated .dark-mode .form-check-input:invalid:focus, .dark-mode .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(231, 104, 104, 0.25);
}
.was-validated .dark-mode .form-check-input:invalid ~ .form-check-label, .dark-mode .form-check-input.is-invalid ~ .form-check-label {
  color: #e76868;
}
.dark-mode .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .dark-mode .input-group .form-control:invalid, .dark-mode .input-group .form-control.is-invalid,
.was-validated .dark-mode .input-group .form-select:invalid,
.dark-mode .input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .dark-mode .input-group .form-control:invalid:focus, .dark-mode .input-group .form-control.is-invalid:focus,
.was-validated .dark-mode .input-group .form-select:invalid:focus,
.dark-mode .input-group .form-select.is-invalid:focus {
  z-index: 3;
}
.dark-mode .form-control.form-control-alt {
  color: #c8ccd0;
  border-color: #252732;
  background-color: #252732;
}
.dark-mode .form-control.form-control-alt:focus {
  color: #fff;
  border-color: #22242e;
  background-color: #22242e;
  box-shadow: none;
}
.dark-mode .form-control.form-control-alt.is-valid {
  border-color: rgba(20, 83, 45, 0.85);
  background-color: rgba(20, 83, 45, 0.85);
}
.dark-mode .form-control.form-control-alt.is-valid::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .form-control.form-control-alt.is-valid:focus {
  border-color: #14532d;
  background-color: #14532d;
}
.dark-mode .form-control.form-control-alt.is-invalid {
  border-color: rgba(127, 29, 29, 0.85);
  background-color: rgba(127, 29, 29, 0.85);
}
.dark-mode .form-control.form-control-alt.is-invalid::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .form-control.form-control-alt.is-invalid:focus {
  border-color: #7f1d1d;
  background-color: #7f1d1d;
}
.dark-mode .input-group-text {
  color: #c8ccd0;
  background-color: #252732;
  border-color: #3d4052;
}
.dark-mode .input-group-text.input-group-text-alt {
  background-color: #22242e;
  border-color: #22242e;
}
.dark-mode .is-valid ~ .valid-feedback,
.dark-mode .is-valid ~ .valid-tooltip,
.dark-mode .was-validated :valid ~ .valid-feedback,
.dark-mode .was-validated :valid ~ .valid-tooltip,
.dark-mode .is-invalid ~ .invalid-feedback,
.dark-mode .is-invalid ~ .invalid-tooltip,
.dark-mode .was-validated :invalid ~ .invalid-feedback,
.dark-mode .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.dark-mode .form-check-input {
  background-color: #252732;
  border-color: #3d4052;
}
.dark-mode .form-check-input:focus {
  border-color: #065E19;
}
.dark-mode .form-check-input:checked {
  background-color: #065E19;
  border-color: #065E19;
}
.dark-mode .form-block .form-check-label {
  border-color: #3d4052;
}
.dark-mode .form-block .form-check-label:hover {
  border-color: #414457;
}
.dark-mode .form-block .form-check-label::before {
  background-color: #065E19;
}
.dark-mode .form-block .form-check-input:checked ~ .form-check-label {
  border-color: #065E19;
}
.dark-mode .form-block .form-check-input:focus ~ .form-check-label {
  border-color: #065E19;
  box-shadow: 0 0 0 0.25rem rgba(6, 94, 25, 0.25);
}
.dark-mode .form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.dark-mode .form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #22242e;
}
.dark-mode .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.15);
}
.dark-mode .breadcrumb.breadcrumb-alt .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.15);
}
.dark-mode .alert-primary {
  color: #fff;
  background-color: #065E19;
  border-color: #065E19;
  box-shadow: none;
}
.dark-mode .alert-primary hr {
  border-top-color: #044613;
}
.dark-mode .alert-primary .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-secondary {
  color: #fff;
  background-color: #393c4d;
  border-color: #393c4d;
  box-shadow: none;
}
.dark-mode .alert-secondary hr {
  border-top-color: #2e303e;
}
.dark-mode .alert-secondary .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-success {
  color: #fff;
  background-color: #4c7a0a;
  border-color: #4c7a0a;
  box-shadow: none;
}
.dark-mode .alert-success hr {
  border-top-color: #3d6208;
}
.dark-mode .alert-success .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-info {
  color: #fff;
  background-color: #066d86;
  border-color: #066d86;
  box-shadow: none;
}
.dark-mode .alert-info hr {
  border-top-color: #05596e;
}
.dark-mode .alert-info .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-warning {
  color: #fff;
  background-color: #b04209;
  border-color: #b04209;
  box-shadow: none;
}
.dark-mode .alert-warning hr {
  border-top-color: #983908;
}
.dark-mode .alert-warning .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-danger {
  color: #fff;
  background-color: #a51d1d;
  border-color: #a51d1d;
  box-shadow: none;
}
.dark-mode .alert-danger hr {
  border-top-color: #8f1919;
}
.dark-mode .alert-danger .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-dark {
  color: #fff;
  background-color: #1f2029;
  border-color: #1f2029;
  box-shadow: none;
}
.dark-mode .alert-dark hr {
  border-top-color: #14151b;
}
.dark-mode .alert-dark .alert-link {
  color: #e6e6e6;
}
.dark-mode .alert-light {
  color: #fff;
  background-color: #46495e;
  border-color: #46495e;
  box-shadow: none;
}
.dark-mode .alert-light hr {
  border-top-color: #3b3e4f;
}
.dark-mode .alert-light .alert-link {
  color: #e6e6e6;
}
.dark-mode .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dark-mode .progress {
  background-color: #22242e;
}
.dark-mode .list-activity > li:not(:last-child) {
  border-bottom-color: #22242e;
}
.dark-mode .modal-content {
  background: #2c2e3b;
}
.dark-mode .toast {
  background-color: #2c2e3b;
}
.dark-mode .toast-header {
  color: #c8ccd0;
  background-color: #393c4d;
}
.dark-mode .border {
  border-color: #393c4d !important;
}
.dark-mode .border-top {
  border-top-color: #393c4d !important;
}
.dark-mode .border-end {
  border-right-color: #393c4d !important;
}
.dark-mode .border-bottom {
  border-bottom-color: #393c4d !important;
}
.dark-mode .border-start {
  border-left-color: #393c4d !important;
}
.dark-mode .border-primary {
  border-color: #065E19 !important;
}
.dark-mode .border-white {
  border-color: #fff !important;
}
.dark-mode .border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.dark-mode .block {
  background-color: #2c2e3b;
  box-shadow: 0 1px 2px rgba(35, 37, 47, 0.5), 0 1px 2px rgba(35, 37, 47, 0.5);
}
.dark-mode .block.block-bordered {
  border: 1px solid #393c4d;
  box-shadow: none;
}
.dark-mode .block .block-header-default {
  background-color: #393c4d !important;
}
.dark-mode .block .block-title small {
  color: #a6acb2;
}
.dark-mode .block.block-mode-loading::after {
  color: #fff;
}
.dark-mode .block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}
.dark-mode .block.block-mode-fullscreen.block-transparent {
  background-color: #2c2e3b;
}
.dark-mode .block .block,
.dark-mode .content-side .block {
  box-shadow: none;
}
.dark-mode a.block {
  color: #c8ccd0;
}
.dark-mode a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2.5rem #1b1c24;
}
.dark-mode a.block.block-link-pop:active {
  box-shadow: 0 0.375rem 0.55rem #2a2c38;
}
.dark-mode a.block.block-link-shadow:hover {
  box-shadow: 0 0 1.5rem #1b1c24;
}
.dark-mode a.block.block-link-shadow:active {
  box-shadow: 0 0 0.75rem #21232c;
}
.dark-mode .btn-block-option {
  color: #a6acb2;
}
.block-header-default .dark-mode .btn-block-option {
  color: #999fa6;
}
.dark-mode .btn-block-option:hover, .dark-mode .btn-block-option:focus {
  color: #8b939a;
}
.dark-mode .btn-block-option:active {
  color: #a6acb2;
}
.dark-mode a.btn-block-option:focus,
.dark-mode .active > a.btn-block-option,
.dark-mode .show > button.btn-block-option {
  color: #8b939a;
}
.dark-mode .block.block-themed .btn-block-option,
.dark-mode .block.block-themed .btn-block-option:hover,
.dark-mode .block.block-themed .btn-block-option:focus,
.dark-mode .block.block-themed .btn-block-option:active,
.dark-mode .block.block-themed a.btn-block-option:focus,
.dark-mode .block.block-themed .active > a.btn-block-option,
.dark-mode .block.block-themed .show > button.btn-block-option {
  color: #fff;
}
.dark-mode .timeline::before {
  background-color: #393c4d;
}
.dark-mode .timeline-event-icon {
  box-shadow: 0 0.375rem 1.5rem #2c2e3b;
}
@media (min-width: 1200px) {
  .dark-mode .timeline-centered .timeline-event-time {
    background-color: #393c4d;
  }
}
.dark-mode .img-thumb {
  background-color: #1f2029;
}
.dark-mode .swal2-popup {
  background-color: #2c2e3b;
}
.dark-mode .swal2-html-container {
  color: #c8ccd0;
}
.dark-mode .jvectormap-container {
  background-color: #2c2e3b !important;
}
.dark-mode .ck.ck-editor {
  color: #3e4254;
}
.dark-mode .dropzone {
  background-color: #333544;
  border-color: #393c4d;
}
.dark-mode .dropzone .dz-message {
  color: #c8ccd0;
}
.dark-mode .dropzone:hover {
  background-color: #393c4d;
  border-color: #065E19;
}
.dark-mode .dropzone:hover .dz-message {
  color: #065E19;
}
.dark-mode .dropzone .dz-preview.dz-image-preview {
  background-color: transparent;
}
.dark-mode .fc-theme-standard a {
  color: #bbbfc4;
}
.dark-mode .fc-theme-standard .fc-button-primary {
  color: #bbbfc4;
  background-color: #22242e;
  border-color: #22242e;
}
.dark-mode .fc-theme-standard .fc-button-primary:not(:disabled):hover {
  color: #c8ccd0;
  background-color: #333544;
  border-color: #333544;
}
.dark-mode .fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active, .dark-mode .fc-theme-standard .fc-button-primary:not(:disabled):active {
  color: #c8ccd0;
  background-color: #333544;
  border-color: #333544;
}
.dark-mode .fc-theme-standard .fc-button-primary:focus, .dark-mode .fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active:focus, .dark-mode .fc-theme-standard .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(6, 94, 25, 0.4);
}
.dark-mode .fc-theme-standard .fc-list, .dark-mode .fc-theme-standard .fc-scrollgrid,
.dark-mode .fc-theme-standard th,
.dark-mode .fc-theme-standard td {
  border-color: #1f2029;
}
.dark-mode .fc-theme-standard .fc-list-day-cushion,
.dark-mode .fc-theme-standard .fc-col-header-cell {
  background-color: #333544;
}
.dark-mode .fc-theme-standard .fc-list-event:hover td {
  background-color: #272834;
}
.dark-mode .irs,
.dark-mode .irs--round .irs-grid-text {
  color: #c8ccd0;
}
.dark-mode .irs.irs--round .irs-min,
.dark-mode .irs.irs--round .irs-max,
.dark-mode .irs.irs--round .irs-line,
.dark-mode .irs.irs--round .irs-grid-pol,
.dark-mode .irs.irs--round .irs-handle {
  color: #c8ccd0;
  background: #252732;
}
.dark-mode .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #768089;
}
.dark-mode .select2-container--default .select2-selection--single,
.dark-mode .select2-container--default .select2-selection--multiple {
  background-color: #22242e;
  border-color: #3d4052;
}
.dark-mode .select2-container--default.select2-container--focus .select2-selection--multiple, .dark-mode .select2-container--default.select2-container--focus .select2-selection--single, .dark-mode .select2-container--default.select2-container--open .select2-selection--multiple, .dark-mode .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #065E19;
}
.dark-mode .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #c8ccd0;
}
.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #3d4052;
}
.dark-mode .select2-container--default .select2-dropdown .select2-search__field:focus {
  border-color: #065E19;
}
.dark-mode .select2-container--default .select2-dropdown {
  background-color: #22242e;
  border-color: #3d4052;
}
.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  color: #c8ccd0;
  background-color: #22242e;
  border-color: #3d4052;
}
.dark-mode .select2-container--default .select2-results__option[aria-selected=true] {
  color: #fff;
  background-color: #065E19;
}
.dark-mode .select2-container--default .select2-search__field::placeholder {
  color: #768089;
}
.dark-mode .is-valid + .select2-container--default .select2-selection--single,
.dark-mode .is-valid + .select2-container--default .select2-selection--multiple,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #82d211;
}
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-mode .is-valid + .select2-container--default.select2-container--open .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(130, 210, 17, 0.25);
}
.dark-mode .is-invalid + .select2-container--default .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default .select2-selection--multiple,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #e35252;
}
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-mode .is-invalid + .select2-container--default.select2-container--open .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(227, 82, 82, 0.25);
}
.dark-mode .datepicker .datepicker-switch:hover,
.dark-mode .datepicker .next:hover,
.dark-mode .datepicker .prev:hover,
.dark-mode .datepicker tfoot tr th:hover,
.dark-mode .datepicker table tr td.day:hover,
.dark-mode .datepicker table tr td.focused {
  background-color: #22242e;
}
.dark-mode .datepicker table tr td.selected,
.dark-mode .datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #22242e;
  border-color: #22242e;
}
.dark-mode .datepicker table tr td.range {
  color: #c8ccd0;
  background-color: #22242e;
  border-color: #22242e;
}
.dark-mode .datepicker table tr td.active:hover,
.dark-mode .datepicker table tr td.active:hover:hover,
.dark-mode .datepicker table tr td.active.disabled:hover,
.dark-mode .datepicker table tr td.active.disabled:hover:hover,
.dark-mode .datepicker table tr td.active:focus,
.dark-mode .datepicker table tr td.active:hover:focus,
.dark-mode .datepicker table tr td.active.disabled:focus,
.dark-mode .datepicker table tr td.active.disabled:hover:focus,
.dark-mode .datepicker table tr td.active:active,
.dark-mode .datepicker table tr td.active:hover:active,
.dark-mode .datepicker table tr td.active.disabled:active,
.dark-mode .datepicker table tr td.active.disabled:hover:active,
.dark-mode .datepicker table tr td.active.active,
.dark-mode .datepicker table tr td.active:hover.active,
.dark-mode .datepicker table tr td.active.disabled.active,
.dark-mode .datepicker table tr td.active.disabled:hover.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active:hover,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dark-mode .datepicker table tr td span.active:hover,
.dark-mode .datepicker table tr td span.active:hover:hover,
.dark-mode .datepicker table tr td span.active.disabled:hover,
.dark-mode .datepicker table tr td span.active.disabled:hover:hover,
.dark-mode .datepicker table tr td span.active:focus,
.dark-mode .datepicker table tr td span.active:hover:focus,
.dark-mode .datepicker table tr td span.active.disabled:focus,
.dark-mode .datepicker table tr td span.active.disabled:hover:focus,
.dark-mode .datepicker table tr td span.active:active,
.dark-mode .datepicker table tr td span.active:hover:active,
.dark-mode .datepicker table tr td span.active.disabled:active,
.dark-mode .datepicker table tr td span.active.disabled:hover:active,
.dark-mode .datepicker table tr td span.active.active,
.dark-mode .datepicker table tr td span.active:hover.active,
.dark-mode .datepicker table tr td span.active.disabled.active,
.dark-mode .datepicker table tr td span.active.disabled:hover.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #065E19;
  border-color: #065E19;
}
.dark-mode .flatpickr-input.form-control:disabled,
.dark-mode .flatpickr-input.form-control[readonly],
.dark-mode .input.form-control:disabled,
.dark-mode .input.form-control[readonly] {
  color: #c8ccd0;
  background-color: #22242e;
  border-color: #3d4052;
}
.dark-mode .flatpickr-day.selected,
.dark-mode .flatpickr-day.startRange,
.dark-mode .flatpickr-day.endRange,
.dark-mode .flatpickr-day.selected.inRange,
.dark-mode .flatpickr-day.startRange.inRange,
.dark-mode .flatpickr-day.endRange.inRange,
.dark-mode .flatpickr-day.selected:focus,
.dark-mode .flatpickr-day.startRange:focus,
.dark-mode .flatpickr-day.endRange:focus,
.dark-mode .flatpickr-day.selected:hover,
.dark-mode .flatpickr-day.startRange:hover,
.dark-mode .flatpickr-day.endRange:hover,
.dark-mode .flatpickr-day.selected.prevMonthDay,
.dark-mode .flatpickr-day.startRange.prevMonthDay,
.dark-mode .flatpickr-day.endRange.prevMonthDay,
.dark-mode .flatpickr-day.selected.nextMonthDay,
.dark-mode .flatpickr-day.startRange.nextMonthDay,
.dark-mode .flatpickr-day.endRange.nextMonthDay {
  border-color: #065E19;
  background: #065E19;
}
.dark-mode .flatpickr-months .flatpickr-prev-month:hover svg,
.dark-mode .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #065E19;
}